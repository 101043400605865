/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  ptBR
} from '@mui/x-data-grid'
import { CustomTableWrapper } from './style'
import NoData from './NoData'
import NoResultsFound from './NoResultsFound'
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps'

interface CustomTableProps
  extends Omit<DataGridProps, 'autoHeight' | 'selectionModel' | 'rows'> {
  data?: any[]
  columns: GridColDef[]
  isLoading?: boolean
  onPageChange?: any
  onPageSizeChange?: any
  page?: number
  pageSize?: number
  setPage?: (e: any) => void
  setPageSize?: (e: any) => void
  paginationMode?: GridFeatureMode
  rowCount?: number
  checkboxSelection?: boolean
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue
  getIsRowInactive?: (params: GridRowClassNameParams<any>) => boolean
  selectionModel?: (e: any) => void
  autoHeight?: boolean
  selecteds?: string[]
  disableSelectionOnClick?: boolean
  disableSelectAllCheckbox?: boolean
}

export default function CustomTable({
  data,
  columns,
  isLoading,
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  setPage,
  setPageSize,
  paginationMode = 'server',
  rowCount,
  checkboxSelection,
  getRowHeight,
  getIsRowInactive,
  selectionModel,
  autoHeight,
  selecteds,
  disableSelectionOnClick = true,
  disableSelectAllCheckbox,
  ...props
}: CustomTableProps) {
  const [rowCountState, setRowCountState] = useState(rowCount)
  const [dataState, setDataState] = useState<any[]>()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    )
  }, [rowCount])

  useEffect(() => {
    setTimeout(() => {
      data && setDataState(data)
    }, 500)
  }, [data])

  return (
    <CustomTableWrapper autoHeight={autoHeight}>
      <DataGrid
        {...props}
        rows={dataState || []}
        columns={columns}
        rowsPerPageOptions={[10, 25, 100]}
        page={page}
        pageSize={pageSize}
        paginationMode={paginationMode}
        onPageChange={(newPage) => {
          if (setPage) setPage(newPage)
          if (onPageChange) onPageChange(newPage, pageSize)
        }}
        onPageSizeChange={(newPageSize) => {
          if (setPageSize) setPageSize(newPageSize)
          if (onPageSizeChange) onPageSizeChange(page, newPageSize)
        }}
        loading={isLoading}
        rowCount={rowCountState || data?.length}
        disableSelectionOnClick={disableSelectionOnClick}
        checkboxSelection={checkboxSelection}
        components={{
          NoRowsOverlay: () => {
            return <NoData />
          },
          NoResultsOverlay: () => {
            return <NoResultsFound />
          }
        }}
        sx={{
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel':
            {
              marginTop: '1em',
              marginBottom: '1em'
            },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
            {
              display: disableSelectAllCheckbox ? 'none' : 'initial'
            },
          '& .inactive, & .inactive.Mui-selected, & .inactive:hover': {
            background: '#ffe2e2'
          },
          '.inactive:hover': {
            background: '#ffe7e7'
          }
        }}
        disableVirtualization
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        getRowHeight={(params) => getRowHeight?.(params)}
        // onSelectionModelChange={selectionModel}
        selectionModel={selecteds}
        autoHeight={autoHeight}
        getRowClassName={(params) => {
          return getIsRowInactive?.(params) ? 'inactive' : ''
        }}
        onCellClick={(params) => {
          if (params.field === '__check__') {
            const selectedIds = [...(selecteds || [])]
            const index = selectedIds.indexOf(params.id as string)
            if (index === -1) {
              selectedIds.push(params.id as string)
            } else {
              selectedIds.splice(index, 1)
            }
            selectionModel?.(selectedIds)
          }
        }}
        onColumnHeaderClick={(params) => {
          if (params.field === '__check__') {
            if (dataState?.length === selecteds?.length) {
              selectionModel?.([])
            } else {
              const selectedIds = dataState?.map((item) => item.id)
              selectionModel?.(selectedIds)
            }
          }
        }}
      />
    </CustomTableWrapper>
  )
}
