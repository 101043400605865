/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnumProdutos } from 'models/enums'
import {
  Convenio,
  Paginacao,
  ProdutosSimulacao,
  Profissao,
  Telefone
} from 'models/types'
import { Tabulacao } from 'models/types'

export interface CadastroSimplificadoReq {
  nome: string
  cpf: string
  dataNascimento: string
  matricula?: string | null
  ddd: string
  telefone: string
  perfilId: string
  convenioId?: string | null
  margem?: number | null
  margemCartao?: number | null
  sessaoServidor?: boolean
}

export interface RequestValidacaoNovoAtendimentoDto {
  cpf: string
  novoAtendimento: boolean
  tipoAtendimento: number
  campanhaCrmId: string | null
  tipoProdutoEnum: number | null
  consultorCarteiraId: string | null
}

/**Tab Contato*/

export interface TabContato {
  nome: string
  dataNascimento: string
  cidade: string
  sexo: string
  tabulacoes: Tabulacao[]
  tiposAgendamentos: TipoAgendamento[]
  tipoChamada: number
  infosChamada: InfosChamada
  infosChamadaInvalid: InfosChamadaInvalid
  agendamentos?: Agendamentos[]
  tabelaAtendimentos: Paginacao
  atendimentoId: string
  clienteRiber: boolean
  isOpenEnriquecimento: boolean
  enriquecido: boolean
  tipoProduto: number
  campanhaCrmId: string
  consultorId: string | null
  tipoAtendimento: number
  atendimentoAgendadoId: string | null
  integracaoUrl: string
  mensagemErroIntegracao?: string | null
  clienteSite: boolean
}

export interface FinalizaAgendamentoDto {
  atendimentoId: string
  atendimentoAgendadoId: string
}

export interface TipoAgendamento {
  descricao: string
  atendimentoAgendado: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: string
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: string
}

export interface InfosChamada {
  telefones?: {
    telefoneId: string
    numero: string
    whatsapp: boolean
  }[]
  numeroChamada: string
  tabulacaoChamada: Tabulacao | null
  dataAgendamento: string
  horarioAgendamento: string
  tipoAgendamento: string
  observacoesAgendamento: string
  emChamada: boolean
  statusChamada: StatusChamadaEnum
  modalDiscagem: boolean
  numerosDiscados: string[]
  callId: string
  infosMatriculasLead: InfosMatriculaLead[]
}

export enum StatusChamadaEnum {
  naoIniciado = 0,
  discando = 1,
  atendida = 2,
  finalizada = 3,
  erro = 4
}

export interface InfosChamadaInvalid {
  dataAgendamento: boolean
  horarioAgendamento: boolean
  tipoAgendamento: boolean
}

export interface Oferta {
  descricao: string
  valor: string
}
export interface InfosMatriculaLead {
  matricula: string
  descricaoPerfil: string
  descricaoStepLead: string
  exibeMatricula: boolean
  observacaoLead: string
  produtosLead: Oferta[]
}

export interface Agendamentos {
  id: string
  descricaoTabulacao?: string
  canal: string
  dataChamada: string
  duracao: string
  nomeAtendente: string
  ddd: string
  telefone: string
}

export interface Cliente {
  valorRendaIndividual: number
  profissao: any
  pessoaId: string
  margemConsignadoCartao: number
  escolaridadeId: string
  valorDesejado: any
  margemDisponivel: number
  bmg: any
  sexo: string
  estadoCivil: number
  nomeConjuge: any
  cidadeNascimento: string
  ufNascimento: string
  nacionalidade: string
  pessoa: Pessoa
  clienteMatricula: any
  convenios: any
  escolaridade: any
  abandonoCliente: any
  atendimentos: any[]
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: string
}

export interface Pessoa {
  flagJuridica: boolean
  nome: string
  cpfCnpj: string
  nomeMae: string
  nomePai: string
  dataNascimento: string
  ie: any
  im: any
  rg: string
  rgOrgaoEmissorId: string
  isento: any
  nomeFantasia: any
  estadoOrgaoEmissorId: string
  dataEmissao: string
  enderecos: any
  telefones: Telefone[]
  usuariosBancos: any[]
  formulariosAtendimento: any
  contaCorrente: any
  estadoOrgaoEmissor: any
  rgOrgaoEmissor: any
  atendimentos: any
  telefonesCondenados: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: string
}

export interface CanalAtendimento {
  descricao: string
  atendimentos: any[]
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: any
}

export interface Atendente {
  flagJuridica: boolean
  nome: string
  cpfCnpj: string
  nomeMae: string
  nomePai: string
  dataNascimento: string
  ie: any
  im: any
  rg: string
  rgOrgaoEmissorId: string
  isento: any
  nomeFantasia: any
  estadoOrgaoEmissorId: string
  dataEmissao: any
  cliente: any
  enderecos: any
  telefones: any
  usuariosBancos: any[]
  formulariosAtendimento: any
  contaCorrente: any
  estadoOrgaoEmissor: any
  rgOrgaoEmissor: any
  atendimentos: any[]
  telefonesCondenados: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}

export interface TabulacaoAtendimento {
  atendimentoId: string
  cpf: string
  ddd: string
  telefone: string
  tabulacaoId?: string
  dataAtendimento?: string
  horario?: string
  tipoAgendamentoId: string
  observacoes?: string
  tabulacaoAutomaticaId?: string
  tipoAtendimento: number
  tipoProduto: number
  campanhaCrmId: string
  consultorCarteiraId: string | null
  callId: string
  tabulacaoAutomatica?: boolean
}

export interface ResponseTabulacao {
  telefones: Telefone[]
  novoAtendimento: boolean
  proximoTelefone: boolean
  atendimento: ResponseAtendimentoDto | null
}

export interface ResponseAtendimentoDto {
  atendimentoId: string
  nome: string
  cpfCnpj: string
  matricula: string
  dataNascimento: string | null
  cidade: string
  sexo: string
  telefones: Telefone[]
  descricaoPerfil: string
  complemento: string
  clienteRiber: boolean
  tipoProduto: number | null
  campanhaCrmId: string | null
}

export interface InformacoesChamadaDto {
  usuarioId: string | null
  cpfCliente: string
  telefones: ResponseTelefonesDto[]
  ddd: string
  numero: string
  statusChamada: StatusChamadaEnum
  tabulacao: Tabulacao
  tipoLigacao: '1' | '2'
  callId: string
}

export interface ResponseTelefonesDto {
  ddd: string
  numero: string
}

export interface PlanoSeguroPrestamistaSelecionado {
  matricula: string
  value: number
  valorPremio: number
}

/**Tab Produto*/
export interface TabProduto {
  beneficios: Beneficio[]
  bancoValidacaoSelected: 'bmg' | 'itau'
  telefonesClienteIN100: { value: string; label: string }[]
  somatorioTotalProdutos: number
  somatorioResumo: number
  produtosDisponiveis: ProdutosSimulacao[]
  produtosSelecionados: ProdutosSimulacao[]
  outrosProdutos: any[]
  produtosContratados: ProdutosContratados[]
  detalhamentoIsOpen: boolean
  produtoSelected: {
    servico: number
    matricula: string
  }
  infosClienteResumo: InfosClienteResumo
  modalResumoContratacao: boolean
  loadingSimulacao: boolean
  invalidContratacao: boolean
  modalIndicacao: boolean
  modalIndicacaoManual: boolean
  clienteNaoElegivel: boolean
  mensagemModalIndicacao: string
  matriculasModalIndicacao: string[]
  modalAgregacao: boolean
  modalInegibilidade: boolean
  mensagemInegibilidade: string
  servico: number
  matricula: string
  listProdutosRefinQtdDeParcelasOriginaisByContrato: {
    contrato: string
    value: number
  }[]
  listCodigoTabelaSelectedByCodigoSimulacao: { id: number; value: number }[]
  listParcelasSelectedByCodigoSimulacao: { id: number; value: number }[]
  margemDisponivelByMatricula: { matricula: string; value: number }[]
  listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula: PlanoSeguroPrestamistaSelecionado[]
  listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula: PlanoSeguroPrestamistaSelecionado[]
  listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula: PlanoSeguroPrestamistaSelecionado[]
  listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula: PlanoSeguroPrestamistaSelecionado[]
  modalCoberturasSeguroPrestamista: boolean
  modalSeguroSindnapi: boolean
}

export interface Beneficio {
  numero: string
  dataAtualizacaoToken: string
  dataConsentimento: string
  dataRespostaIn100: string
  clientePerfil: ClientePerfil
  loading: boolean
}

interface ClientePerfil {
  id: string
  descricao: string
  tipoPerfil: number
}

export interface InfosClienteResumo {
  nome: string
  cpf: string
  dataNascimento: string
  numeroIdentidade: string
  emissorIdentidade: string
  ufIdentidade: string
  dataEmissao: string
  nomePai: string
  nomeMae: string
  cep: string
  logradouro: string
  numeroEndereco: string
  bairro: string
  cidade: string
  uf: string
  complemento: string
  numero: string
  ddd: string
  sexo: string
  estadoCivil: string
  nomeConjuge: string
  cidadeNascimento: string
  ufNascimento: string
  nacionalidade: string
  grauInstrucao: string
  matriculas: MatriculaResumo[]
}

export interface MatriculaResumo {
  id: string
  numeroMatricula: string
  tipoPerfil: number
  descricaoPerfil: string
  tipoPerfilId: string
  convenioId?: string
  margemDisponivel: number
  margemCartaoDisponivel: number
  margemCartaoBeneficio: number
  descricaoBanco: string
  contaCorrenteId: string
  tipoContaId: string
  bancoDadosBancariosId: string
  numeroAgencia: string
  digitoVerificadorAgencia: any
  numeroConta: string
  digitoVerificadorConta: string
}

export interface ProdutosContratados {
  cpf: string
  nomeCliente: string
  numeroProposta: string
  produto: keyof typeof EnumProdutos
  valorTotal: number
  quantidadeParcelas: number
  valorParcela: number
  valorLiberado: number
  usuarioInstituicao: any
  status: number
  banco: string
  numeroMatricula: string
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataContratacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}

/**Tab Informações*/

export interface TabInformacoes {
  infoCliente: InfoCliente
  invalids: CamposInfosCliente
  optionsSelect: OptionsSelect
  activeTabBeneficio: number
}

export interface InfoCliente {
  nome: string
  cpf: string
  dataNascimento: string
  nomePai?: string | null
  nomeMae: string
  numeroIdentidade: string
  orgaoEmissorId: string
  ufOrgaoEmissor: string
  dataEmissao: string
  ddd: string
  numero: string
  email: any
  sexo: string
  estadoCivil: string | null
  nomeConjuge?: string | null
  cidadeNascimento: string
  ufNascimento: string
  matriculas: Matricula[] | null
  escolaridadeId: string
  cep: string
  cidade: string
  siglaEstado: string
  logradouro: string
  numeroEndereco: string
  bairro: string
  complemento?: string | null
  profissaoId: string
}

export interface Matricula {
  id?: string | null
  numeroMatricula?: string | null
  tipoPerfil?: number | null
  descricaoPerfil?: string | null
  tipoPerfilId?: string | null
  convenioId?: string | null
  margemDisponivel?: string | null
  margemCartaoDisponivel?: string | null
  margemCartaoBeneficio?: string | null
  contaCorrenteId?: string | null
  tipoContaId: string
  bancoDadosBancariosId: string
  numeroAgencia: number | null
  digitoVerificadorAgencia?: number | null
  numeroConta: number | null
  digitoVerificadorConta: number | null
}

export interface MatriculaReq {
  id?: string | null
  numeroMatricula?: string | null
  tipoPerfil?: number | null
  descricaoPerfil?: string | null
  tipoPerfilId: string
  convenioId?: string | null
  margemDisponivel?: number | null
  margemCartaoDisponivel?: number | null
  margemCartaoBeneficio?: number | null
  contaCorrenteId?: string | null
  tipoContaId: string
  bancoDadosBancariosId: string
  numeroAgencia: number
  digitoVerificadorAgencia?: number | null
  numeroConta: number
  digitoVerificadorConta: number
}

export interface InfoClienteReq {
  nome: string
  cpf: string
  dataNascimento: string
  nomePai?: string | null
  nomeMae: string
  numeroIdentidade: string
  orgaoEmissorId: string
  ufOrgaoEmissor: string
  dataEmissao: string
  ddd: string
  numero: string
  email: any
  sexo: string
  estadoCivil: string | null
  nomeConjuge?: string | null
  cidadeNascimento: string
  ufNascimento: string
  matriculas: MatriculaReq[]
  escolaridadeId: string
  cep: string
  cidade: string
  siglaEstado: string
  logradouro: string
  numeroEndereco: string
  bairro: string
  complemento?: string | null
  profissaoId: string
}
export interface CamposInfosCliente {
  nome: boolean
  cpf: boolean
  dataNascimento: boolean
  nomePai: boolean
  nomeMae: boolean
  numeroIdentidade: boolean
  orgaoEmissorId: boolean
  ufOrgaoEmissor: boolean
  dataEmissao: boolean
  ddd: boolean
  numero: boolean
  email: boolean
  sexo: boolean
  estadoCivil: boolean
  nomeConjuge: boolean
  cidadeNascimento: boolean
  ufNascimento: boolean
  matriculas: MatriculaInvalid[]
  escolaridadeId: boolean
  cep: boolean
  cidade: boolean
  siglaEstado: boolean
  logradouro: boolean
  numeroEndereco: boolean
  bairro: boolean
  complemento: boolean
  profissaoId: boolean
}

export interface MatriculaInvalid {
  id: boolean
  numeroMatricula: boolean
  tipoPerfil: boolean
  descricaoPerfil: boolean
  tipoPerfilId: boolean
  convenioId: boolean
  margemDisponivel: boolean
  margemCartaoDisponivel: boolean
  margemCartaoBeneficio: boolean
  contaCorrenteId: boolean
  tipoContaId: boolean
  bancoDadosBancariosId: boolean
  numeroAgencia: boolean
  digitoVerificadorAgencia: boolean
  numeroConta: boolean
  digitoVerificadorConta: boolean
}

interface Genero {
  value: string
  descricao: string
}

interface OrgaoEmissor {
  nome: string
  pessoas: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: any
}

interface Estado {
  nome: string
  sigla: string
  endereco: any
  pessoas: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: string
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: string
}

interface Escolaridade {
  numero: number
  descricao: string
  cliente: any
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: string
  dataUltimaAlteracao: string
}
interface EstadoCivil {
  value: number
  descricao: string
}

interface TipoConta {
  value: number
  descricao: string
}

interface Banco {
  id: string
  codigoBanco: string
  descricao: string
}

interface Cidade {
  nome: string
  sigla: string
  id: string
  ativo: boolean
  usuario: any
  idUsuarioCriacao: string
  dataCriacao: string
  usuarioAlteracao: any
  idUsuarioUltimaAlteracao: any
  dataUltimaAlteracao: any
}

export interface OptionsSelect {
  genero: Genero[]
  orgaoEmissor: OrgaoEmissor[]
  ufOrgaoEmissor: Estado[]
  escolaridade: Escolaridade[]
  ufNascimento: Estado[]
  cidadeNascimento: Cidade[]
  estadoCivil: EstadoCivil[]
  uf: Estado[]
  cidade: Cidade[]
  tipoConta: TipoConta[]
  banco: Banco[]
  convenios: Convenio[]
  profissoes: Profissao[]
}

/**Tab Histórico*/

export interface TabHistorico {
  contratos: Contratos[]
  tabelaContratos: Paginacao
  infosConsig?: InfosConsig
}

export interface InfosConsig {
  nome: string
  idade: string
  valorRenda: number
  margemDisponivel: number
  margemCartaoConsignado: number
  margemCartaoBeneficio: number
  situacao: string
  especie: string
  possuiRepresentanteLegalProcurador: string
  bloqueioEmprestismo: string
  beneficioPermiteEmprestimo: string
  pensaoAlimenticia: string
  dataDespachoBeneficio: string
}

export interface Contratos {
  id: string
  matricula: string
  codigoBanco: string
  banco: string
  numeroContrato: string
  valorContrato: number
  tipoEmprestimo: string
  inicioContrato: string
  fimContrato: string
  parcelasEmAberto: number
  quantidadeParcelas: number
  valorParcela: number
  dataAverbacao: string
  situacao: string
  taxa: number
  saldoDevedorAproximado: number
  valorReservado: number
}
