import {
  Convenio,
  ProdutosSimulacao,
  Telefone,
  Perfil,
  Profissao,
  ResponseWithMessages,
  ReqFgts,
  ReqCrmSimulacao,
  ListaParcelasEValorTotalSimulacao
} from 'models/types'
import { SagaIterator } from 'redux-saga'
import {
  call,
  fork,
  put,
  all,
  takeEvery,
  select,
  CallEffect,
  delay,
  AllEffect
} from 'redux-saga/effects'
import { ApplicationState } from 'store'
import { ATENDIMENTO, TYPES } from './types'
import {
  Beneficio,
  InfosConsig,
  InfosMatriculaLead,
  Matricula,
  MatriculaInvalid,
  MatriculaResumo,
  ResponseTabulacao
} from 'models/atendimento/types'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import {
  maskCEP,
  maskCPF,
  maskDate,
  maskMoney,
  maskNumber,
  maskPhoneWithDDD,
  removeMaskCEP,
  removeMaskCPF,
  removeMaskMoney
} from 'util/masks'
import {
  CadastroSimplificadoReq,
  Contratos,
  InfoCliente,
  InfosChamada,
  InfosClienteResumo,
  ProdutosContratados,
  TabulacaoAtendimento
} from 'models/atendimento/types'
import * as atendimentoController from 'controller/atendimentoController'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import * as inicioActions from 'store/modules/inicio/actions'
import * as loadingActions from 'store/modules/loading/actions'
import moment from 'moment'
import history from 'util/history'
import { handlerErrorFgts } from 'util/handlerErrorFgts'
import { AxiosResponse } from 'axios'
import { ReqCrm } from 'models/types'
import { handlerErrorSimulacao } from 'util/handlerErroSimulacao'
import { handlerErrorSimulacaoFgts } from 'util/handlerErrorSimulacaoFGTS'

interface GenericProps {
  type: string
  cpf: string
  index: number
}

function* getFormularioAtendimento30({ cpf }: GenericProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield put(atendimentoActions.setInfosFormulario(cpf, 'cpf'))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.getFormularioAtendimento30,
      cpfFormated
    )
    if (!response?.data?.content) {
      yield put(atendimentoActions.toggleFormularioNecessidade(true))
    } else {
      yield put(atendimentoActions.startAtendimentoSaga(cpf))
      if (window.location.pathname.toLowerCase() !== '/atendimento') {
        history.push('/atendimento')
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message === 'Pessoa não encontrada.') {
      yield put(atendimentoActions.toggleConfirmacaoCadastro(true))
      yield put(atendimentoActions.cleanInfosCadastroClienteModal())
    } else {
      handlerError(error, 'Não foi possível iniciar o atendimento!')
    }
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarFormularioAtendimento30({ cpf }: GenericProps): SagaIterator {
  const { formularioNecessidade }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(
      atendimentoController.salvarFormularioAtendimento30,
      formularioNecessidade.answers
    )
    yield put(atendimentoActions.toggleFormularioNecessidade(false))
    yield put(
      atendimentoActions.startAtendimentoSaga(
        cpf,
        formularioNecessidade.answers.indexTabCliente
      )
    )
    yield put(atendimentoActions.cleanInfosFormulario())
    toast.success('Formulário salvo com sucesso!')
    if (window.location.pathname.toLowerCase() !== '/atendimento') {
      history.push('/atendimento')
    }
  } catch (error) {
    handlerError(error, 'Não foi possível salvar o formulário!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetAtendimentosProps extends GenericProps {
  indexTabCliente: number
}

function* getAtendimentos({
  cpf,
  indexTabCliente
}: GetAtendimentosProps): SagaIterator {
  try {
    yield put(atendimentoActions.setLoadingStartAtendimento(true))
    if (cpf.length !== 14) {
      return toast.warn('Digite um CPF para iniciar um atendimento')
    }
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.iniciarAtendimento,
      cpfFormated
    )
    yield put(atendimentoActions.cleanCpfAtendimento())
    yield put(atendimentoActions.startAtendimento(cpfFormated, indexTabCliente))
    const telefones: InfosChamada['telefones'] = []
    response?.data?.content?.telefones.forEach((telefone: Telefone) => {
      telefones.push({
        numero: `(${telefone.ddd}) ${telefone.numero}`,
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })

    yield put(
      atendimentoActions.setInfosContato(
        {
          nome: response?.data?.content?.nome,
          dataNascimento: moment(
            response?.data?.content?.dataNascimento
          ).format('DD/MM/YYYY'),
          cidade: response?.data?.content?.cidade,
          sexo: response?.data?.content?.sexo,
          clienteRiber: response?.data?.content?.clienteRiber,
          infosChamada: {
            telefones: telefones,
            dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
            duracaoChamada: '00:00',
            horarioAgendamento: moment().format('HH:mm'),
            numeroChamada: [],
            observacoesAgendamento: '',
            tabulacaoChamada: null,
            tipoAgendamento: '',
            emChamada: false,
            infosMatriculasLead: []
          },
          atendimentoId: response?.data?.content?.atendimentoId,
          tipoProduto: response?.data?.content?.tipoProduto,
          campanhaCrmId: response?.data?.content?.campanhaCrmId,
          atendimentoAgendadoId: response?.data?.content?.atendimentoAgendadoId,
          tipoAtendimento: 0,
          integracaoUrl: '',
          clienteSite: response?.data?.content?.clienteSite
        },
        cpfFormated
      )
    )
    yield put(
      atendimentoActions.setModalAtendimentoAgendado(
        !!response?.data?.content?.atendimentoAgendadoId,
        cpfFormated
      )
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message === 'Pessoa/Cliente não encontrado') {
      yield put(atendimentoActions.toggleConfirmacaoCadastro(true))
      yield put(atendimentoActions.cleanInfosCadastroClienteModal())
    } else {
      handlerError(error, 'Não foi possível iniciar um atendimento')
    }
  } finally {
    yield put(atendimentoActions.setLoadingStartAtendimento(false))
  }
}

function* finalizaAgendamento({ index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(atendimentoController.finalizaAgendamento, {
      atendimentoAgendadoId:
        atendimentosAbertos[index].tabContato.atendimentoAgendadoId ?? '',
      atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId
    })
    yield put(
      atendimentoActions.setModalAtendimentoAgendado(
        false,
        atendimentosAbertos[index].cpf
      )
    )
    toast.success('Atendimento agendado realizado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível confirmar o atendimento agendado!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface EnriquecerDadosProps extends GenericProps {
  automatico?: boolean
}

function* enriquecerDados({
  cpf,
  index,
  automatico
}: EnriquecerDadosProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(atendimentoController.getTelefones, cpf)
    if ((response?.data?.content?.length <= 0 && automatico) || !automatico) {
      const responseEnriquecimento = yield call(
        atendimentoController.enriquecerDados,
        cpf
      )
      const telefones: InfosChamada['telefones'] = []
      responseEnriquecimento?.data?.content?.forEach((telefone: Telefone) => {
        telefones.push({
          numero: `(${telefone.ddd}) ${telefone.numero}`,
          whatsapp: telefone.whatsapp,
          telefoneId: telefone.telefoneId
        })
      })
      yield put(
        atendimentoActions.setInfosContato(
          {
            ...atendimentosAbertos[index].tabContato,
            infosChamada: {
              ...atendimentosAbertos[index].tabContato.infosChamada,
              telefones: telefones
            }
          },
          removeMaskCPF(cpf)
        )
      )
      toast.success('Enriquecimento efetuado com sucesso!')
      yield put(atendimentoActions.getInformacoesClienteSaga(cpf, index))
    }
    if (response?.data?.content?.length > 0 && automatico) {
      yield put(atendimentoActions.validarNovoAtendimentoSaga(cpf, index))
    }
  } catch (error) {
    handlerErrorSimulacao(error, 'Não foi possível enriquecer os dados!')
    yield put(atendimentoActions.validarNovoAtendimentoSaga(cpf, index))
  } finally {
    yield put(atendimentoActions.setEnriquecido(index))
    yield put(loadingActions.setLoading(false))
  }
}

function* validarNovoAtendimento({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const novoAtendimento =
      !!atendimentosAbertos[index].tabContato.campanhaCrmId ||
      (!!atendimentosAbertos[index].tabContato.consultorId &&
        !!atendimentosAbertos[index].tabContato.tipoProduto) ||
      !!atendimentosAbertos[index].tabContato.infosChamadaInvalid

    const response = yield call(atendimentoController.validarNovoAtendimento, {
      cpf: cpf,
      campanhaCrmId: atendimentosAbertos[index].tabContato.campanhaCrmId,
      consultorCarteiraId: atendimentosAbertos[index].tabContato.consultorId,
      novoAtendimento: novoAtendimento,
      tipoAtendimento: atendimentosAbertos[index].tabContato.tipoAtendimento,
      tipoProdutoEnum: atendimentosAbertos[index].tabContato.tipoProduto
    })
    if (response?.data?.content) {
      yield put(atendimentoActions.endAtendimento(index))
      yield put(
        atendimentoActions.startAtendimento(
          response?.data?.content?.atendimento.cpfCnpj,
          1
        )
      )
      const telefones: InfosChamada['telefones'] = []
      response?.data?.content?.atendimento.telefones?.forEach(
        (telefone: Telefone) => {
          telefones.push({
            numero: `(${telefone.ddd}) ${telefone.numero}`,
            whatsapp: telefone.whatsapp,
            telefoneId: telefone.telefoneId
          })
        }
      )
      if (telefones.length > 0) {
        yield put(
          atendimentoActions.discarClienteSaga(index, telefones[0].telefoneId)
        )
      }

      yield put(
        atendimentoActions.setInfosContato(
          {
            nome: response?.data?.content?.atendimento.nome,
            dataNascimento: moment(
              response?.data?.content?.atendimento.dataNascimento
            ).format('DD/MM/YYYY'),
            cidade: response?.data?.content?.atendimento.cidade,
            sexo: response?.data?.content?.atendimento.sexo,
            clienteRiber: response?.data?.content?.atendimento.clienteRiber,
            infosChamada: {
              telefones: telefones,
              dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
              horarioAgendamento: moment().format('HH:mm'),
              numeroChamada: [],
              observacoesAgendamento: '',
              tabulacaoChamada: null,
              tipoAgendamento: '',
              emChamada: false,
              infosMatriculasLead: []
            },
            atendimentoId: response?.data?.content?.atendimento.atendimentoId,
            tipoProduto: response?.data?.content?.atendimento.tipoProduto,
            campanhaCrmId: response?.data?.content?.atendimento.campanhaCrmId,
            atendimentoAgendadoId:
              response?.data?.content?.atendimento.atendimentoAgendadoId,
            consultorId: atendimentosAbertos[index].tabContato.consultorId,
            tipoAtendimento:
              atendimentosAbertos[index].tabContato.tipoAtendimento,
            integracaoUrl: ''
          },
          response?.data?.content?.atendimento.cpfCnpj
        )
      )
      yield put(
        atendimentoActions.setModalAtendimentoAgendado(
          !!response?.data?.content?.atendimentoAgendadoId,
          removeMaskCPF(response?.data?.content?.cpfCnpj)
        )
      )
    }
  } catch (error) {
    handlerError(error, 'Não foi possível pular para o próximo atendimento')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getConvenios(): SagaIterator {
  try {
    const response = yield call(atendimentoController.getConvenios)
    const convenios: Convenio[] = []
    response?.data?.content?.map((convenio: Convenio) => {
      convenios.push({
        codigoEntidade: convenio.codigoEntidade,
        sequencialOrgao: convenio.sequencialOrgao,
        codigoExibicao: convenio.codigoExibicao,
        descricao: convenio.descricao,
        clientes: convenio.clientes,
        parametrizacaoProdutos: convenio.parametrizacaoProdutos,
        id: convenio.id,
        ativo: convenio.ativo,
        usuario: convenio.usuario,
        idUsuarioCriacao: convenio.idUsuarioCriacao,
        dataCriacao: convenio.dataCriacao,
        usuarioAlteracao: convenio.idUsuarioCriacao,
        idUsuarioUltimaAlteracao: convenio.idUsuarioUltimaAlteracao,
        dataUltimaAlteracao: convenio.dataUltimaAlteracao
      })
    })
    yield put(atendimentoActions.setConveniosModal(convenios))
  } catch (error) {
    //
  }
}

function* getConveniosInfo({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getConvenios)
    const convenios: Convenio[] = []
    response?.data?.content?.map((convenio: Convenio) => {
      convenios.push({
        codigoEntidade: convenio.codigoEntidade,
        sequencialOrgao: convenio.sequencialOrgao,
        codigoExibicao: convenio.codigoExibicao,
        descricao: convenio.descricao,
        clientes: convenio.clientes,
        parametrizacaoProdutos: convenio.parametrizacaoProdutos,
        id: convenio.id,
        ativo: convenio.ativo,
        usuario: convenio.usuario,
        idUsuarioCriacao: convenio.idUsuarioCriacao,
        dataCriacao: convenio.dataCriacao,
        usuarioAlteracao: convenio.idUsuarioCriacao,
        idUsuarioUltimaAlteracao: convenio.idUsuarioUltimaAlteracao,
        dataUltimaAlteracao: convenio.dataUltimaAlteracao
      })
    })
    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        convenios,
        'convenios',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getPerfis(): SagaIterator {
  try {
    const response = yield call(atendimentoController.getPerfis)
    const perfis: Perfil[] = []
    response?.data?.content?.map((perfil: Perfil) => {
      perfis.push({
        id: perfil.id,
        descricao: perfil.descricao,
        tipoPerfil: perfil.tipoPerfil
      })
    })
    yield put(atendimentoActions.setPerfisModal(perfis))
  } catch (error) {
    //
  }
}

function* getProfissoes({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getProfissoes)
    const profissoes: Profissao[] = []
    response.data?.content?.map((profissao: Profissao) => {
      profissoes.push({
        id: profissao.id,
        titulo: profissao.titulo
      })
    })
    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        profissoes,
        'profissoes',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* salvarCadastroSimplificado(): SagaIterator {
  const { modalCadastroCliente }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const infosCliente: CadastroSimplificadoReq = {
      convenioId: modalCadastroCliente.convenioId,
      nome: modalCadastroCliente.nome,
      cpf: removeMaskCPF(modalCadastroCliente.cpf),
      dataNascimento: modalCadastroCliente.dataNascimento,
      matricula: modalCadastroCliente.matricula,
      ddd: modalCadastroCliente.ddd,
      telefone: modalCadastroCliente.telefone,
      perfilId: modalCadastroCliente.perfilId || '',
      margem: Number(removeMaskMoney(modalCadastroCliente.margem)),
      margemCartao: Number(removeMaskMoney(modalCadastroCliente.margemCartao))
    }
    const response = yield call(
      atendimentoController.salvarCadastroSimplificado,
      infosCliente
    )
    yield put(atendimentoActions.toggleCadastroSimplificado(false))
    yield put(atendimentoActions.cleanInfosCadastroClienteModal())
    yield put(atendimentoActions.setInfosFormulario(2, 'indexTabCliente'))
    yield put(
      atendimentoActions.getFormularioAtendimento30Saga(
        maskCPF(response?.data?.content?.cpfCnpj)
      )
    )
    if (window.location.pathname.toLowerCase() !== '/atendimento') {
      history.push('/atendimento')
    }
    toast.success('Cliente cadastrado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não possível realizar o cadastro!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getPerfilCliente({ index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(atendimentoActions.setInfosProduto([], 'beneficios', index))
    const response = yield call(
      atendimentoController.getPerfilCliente,
      atendimentosAbertos[index].cpf
    )
    const perfis: Beneficio[] = []
    response?.data?.content?.map((perfil: Beneficio) => {
      perfis.push({
        clientePerfil: {
          id: perfil.clientePerfil.id,
          descricao: perfil.clientePerfil.descricao,
          tipoPerfil: perfil.clientePerfil.tipoPerfil
        },
        dataAtualizacaoToken: perfil.dataAtualizacaoToken,
        dataRespostaIn100: perfil.dataRespostaIn100,
        dataConsentimento: perfil.dataConsentimento,
        numero: perfil.numero,
        loading: false
      })
    })
    yield put(atendimentoActions.setInfosProduto(perfis, 'beneficios', index))
  } catch (error) {
    //
  }
}

interface DiscarClienteProps extends GenericProps {
  tipoLigacao: number
  telefoneId?: string
}

function* discarCliente({
  index,
  telefoneId
}: DiscarClienteProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(atendimentosAbertos[index].cpf)
    const response = yield call(
      atendimentoController.verificaNaopertube,
      cpfFormated
    )
    if (!response?.data?.content) {
      yield call(atendimentoController.discarCliente, {
        cpf: cpfFormated,
        tipoLigacao: atendimentosAbertos[index].tabContato.tipoChamada,
        atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId,
        telefoneId: telefoneId
      })
      toast.success('Ligação efetuada com sucesso!')
    } else {
      toast.error(
        `Cliente com o CPF ${maskCPF(
          atendimentosAbertos[index].cpf
        )} registrado no não perturbe`,
        {
          bodyClassName: 'text-break',
          autoClose: false
        }
      )
    }
  } catch (error) {
    yield put(
      atendimentoActions.setInfosChamadaContato(false, 'emChamada', index)
    )
    yield put(
      atendimentoActions.setInfosChamadaContato('', 'numeroChamada', index)
    )
    handlerError(error, 'Não foi possível realizar a ligação!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getListaAtendimentos({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const response = yield call(
      atendimentoController.getAtendimentos,
      cpf,
      atendimentosAbertos[index].tabContato.tabelaAtendimentos.page,
      atendimentosAbertos[index].tabContato.tabelaAtendimentos.pageSize
    )
    yield put(
      atendimentoActions.setPaginationAtendimentos(
        response?.data?.content?.quantidadeTotal,
        'totalRegistros',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content?.registros,
        'agendamentos',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getTabulacoes({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getTabulacoes)

    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content,
        'tabulacoes',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content?.registros,
        'agendamentos',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getTiposAgendamento({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getTiposAtendimento)
    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content,
        'tiposAgendamentos',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* tabularAtendimento({ index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    let tabulacao: TabulacaoAtendimento
    if (
      atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
        ?.personalizado
    ) {
      tabulacao = {
        atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId,
        cpf: atendimentosAbertos[index].cpf,
        ddd: atendimentosAbertos[
          index
        ].tabContato.infosChamada.numeroChamada?.substring(1, 3),
        telefone: atendimentosAbertos[
          index
        ].tabContato.infosChamada.numeroChamada
          ?.substring(5)
          .replace('-', ''),
        tabulacaoId:
          atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
            ?.id,
        dataAtendimento:
          atendimentosAbertos[index].tabContato.infosChamada.dataAgendamento,
        horario:
          atendimentosAbertos[index].tabContato.infosChamada.horarioAgendamento,
        tipoAgendamentoId:
          atendimentosAbertos[index].tabContato.infosChamada.tipoAgendamento,
        observacoes:
          atendimentosAbertos[index].tabContato.infosChamada
            .observacoesAgendamento,
        campanhaCrmId: atendimentosAbertos[index].tabContato.campanhaCrmId,
        consultorCarteiraId: atendimentosAbertos[index].tabContato.consultorId,
        tipoAtendimento: atendimentosAbertos[index].tabContato.tipoAtendimento,
        tipoProduto: atendimentosAbertos[index].tabContato.tipoProduto,
        callId: atendimentosAbertos[index].tabContato.infosChamada.callId
      }
    } else {
      tabulacao = {
        atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId,
        cpf: atendimentosAbertos[index].cpf,
        ddd: atendimentosAbertos[
          index
        ].tabContato.infosChamada.numeroChamada?.substring(1, 3),
        telefone: atendimentosAbertos[
          index
        ].tabContato.infosChamada.numeroChamada
          ?.substring(5)
          .replace('-', ''),
        tabulacaoId:
          atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
            ?.id,
        dataAtendimento: '',
        tipoAgendamentoId: '',
        observacoes: '',
        campanhaCrmId: atendimentosAbertos[index].tabContato.campanhaCrmId,
        consultorCarteiraId: atendimentosAbertos[index].tabContato.consultorId,
        tipoAtendimento: atendimentosAbertos[index].tabContato.tipoAtendimento,
        tipoProduto: atendimentosAbertos[index].tabContato.tipoProduto,
        callId: atendimentosAbertos[index].tabContato.infosChamada.callId
      }
    }
    const response: AxiosResponse<ReqCrm<ResponseTabulacao>> = yield call(
      atendimentoController.tabularAtendimento,
      tabulacao
    )
    yield put(
      atendimentoActions.setInfosContato(
        {
          ...atendimentosAbertos[index].tabContato,
          infosChamada: {
            ...atendimentosAbertos[index].tabContato.infosChamada,
            numeroChamada: '',
            tabulacaoChamada: null,
            dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
            horarioAgendamento: moment().format('HH:mm'),
            tipoAgendamento: '',
            observacoesAgendamento: '',
            emChamada: false,
            statusChamada: 0,
            modalDiscagem: false,
            numerosDiscados: []
          }
        },
        removeMaskCPF(atendimentosAbertos[index].cpf)
      )
    )
    if (
      response?.data?.content?.telefones?.length >= 0 ||
      response?.data?.content?.telefones === null
    ) {
      const telefones: InfosChamada['telefones'] = []
      response?.data?.content?.telefones?.forEach((telefone: Telefone) => {
        telefones.push({
          numero: `(${telefone.ddd}) ${telefone.numero}`,
          whatsapp: telefone.whatsapp,
          telefoneId: telefone.telefoneId
        })
      })
      yield put(
        atendimentoActions.setInfosContato(
          {
            ...atendimentosAbertos[index].tabContato,
            infosChamada: {
              ...atendimentosAbertos[index].tabContato.infosChamada,
              telefones: telefones
            }
          },
          removeMaskCPF(atendimentosAbertos[index].cpf)
        )
      )
      if (response?.data?.content?.proximoTelefone && telefones?.length > 0) {
        yield put(
          atendimentoActions.discarClienteSaga(index, telefones?.[0].telefoneId)
        )
      }
    }
    if (response?.data?.content.novoAtendimento) {
      if (response?.data?.content.atendimento?.campanhaCrmId) {
        yield put(
          inicioActions.iniciarAtendimentoCampanhasSaga(
            response?.data?.content.atendimento.campanhaCrmId
          )
        )
      }

      if (response?.data?.content.atendimento?.tipoProduto) {
        yield put(
          inicioActions.iniciarAtendimentoCarteirasSaga(
            atendimentosAbertos[index].tabContato.tipoProduto,
            atendimentosAbertos[index].tabContato.consultorId ?? ''
          )
        )
      }

      // if (atendimentosAbertos[index].tabContato.tipoAtendimento) {
      //   yield put(inicioActions.iniciarAtendimentoLeadSaga())
      // }

      yield put(atendimentoActions.endAtendimento(index))
    }
    yield put(
      atendimentoActions.getAtendimentosSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
    toast.success('Tabulação realizada com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível tabular o atendimento!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface TabularAtendimentoByCpfProps extends GenericProps {
  tabulacaoAutomatica?: boolean
  numeroChamada?: string
}

function* tabularAtendimentobyCpf({
  cpf,
  numeroChamada,
  tabulacaoAutomatica
}: TabularAtendimentoByCpfProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const index = atendimentosAbertos
      .map((atendimento) => {
        return atendimento.cpf
      })
      .indexOf(cpf)
    if (index !== -1) {
      let tabulacao: TabulacaoAtendimento
      if (
        atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
          ?.personalizado
      ) {
        tabulacao = {
          atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId,
          cpf: atendimentosAbertos[index].cpf,
          ddd:
            numeroChamada?.substring(1, 3) ||
            atendimentosAbertos[
              index
            ].tabContato.infosChamada.numeroChamada?.substring(1, 3),
          telefone:
            numeroChamada?.substring(5).replace('-', '') ||
            atendimentosAbertos[index].tabContato.infosChamada.numeroChamada
              ?.substring(5)
              .replace('-', ''),
          tabulacaoId:
            atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
              ?.id,
          dataAtendimento:
            atendimentosAbertos[index].tabContato.infosChamada.dataAgendamento,
          horario:
            atendimentosAbertos[index].tabContato.infosChamada
              .horarioAgendamento,
          tipoAgendamentoId:
            atendimentosAbertos[index].tabContato.infosChamada.tipoAgendamento,
          observacoes:
            atendimentosAbertos[index].tabContato.infosChamada
              .observacoesAgendamento,
          campanhaCrmId: atendimentosAbertos[index].tabContato.campanhaCrmId,
          consultorCarteiraId:
            atendimentosAbertos[index].tabContato.consultorId,
          tipoAtendimento:
            atendimentosAbertos[index].tabContato.tipoAtendimento,
          tipoProduto: atendimentosAbertos[index].tabContato.tipoProduto,
          callId: atendimentosAbertos[index].tabContato.infosChamada.callId,
          tabulacaoAutomatica: !!tabulacaoAutomatica
        }
      } else {
        tabulacao = {
          atendimentoId: atendimentosAbertos[index].tabContato.atendimentoId,
          cpf: atendimentosAbertos[index].cpf,
          ddd:
            numeroChamada?.substring(1, 3) ||
            atendimentosAbertos[
              index
            ].tabContato.infosChamada.numeroChamada?.substring(1, 3),
          telefone:
            numeroChamada?.substring(5).replace('-', '') ||
            atendimentosAbertos[index].tabContato.infosChamada.numeroChamada
              ?.substring(5)
              .replace('-', ''),
          tabulacaoId:
            atendimentosAbertos[index].tabContato.infosChamada.tabulacaoChamada
              ?.id,
          dataAtendimento: '',
          tipoAgendamentoId: '',
          observacoes: '',
          campanhaCrmId: atendimentosAbertos[index].tabContato.campanhaCrmId,
          consultorCarteiraId:
            atendimentosAbertos[index].tabContato.consultorId,
          tipoAtendimento:
            atendimentosAbertos[index].tabContato.tipoAtendimento,
          tipoProduto: atendimentosAbertos[index].tabContato.tipoProduto,
          callId: atendimentosAbertos[index].tabContato.infosChamada.callId,
          tabulacaoAutomatica: !!tabulacaoAutomatica
        }
      }
      const response: AxiosResponse<ReqCrm<ResponseTabulacao>> = yield call(
        atendimentoController.tabularAtendimento,
        tabulacao
      )
      if (tabulacaoAutomatica) {
        let telefonesDiscados = [
          ...atendimentosAbertos[index].tabContato.infosChamada.numerosDiscados
        ]
        telefonesDiscados = telefonesDiscados?.filter(
          (telefone) =>
            telefone !==
            (numeroChamada ||
              maskPhoneWithDDD(
                atendimentosAbertos[
                  index
                ].tabContato.infosChamada.numeroChamada?.substring(1, 3) +
                  atendimentosAbertos[
                    index
                  ].tabContato.infosChamada.numeroChamada
                    ?.substring(5)
                    .replace('-', '')
              ))
        )
        yield put(
          atendimentoActions.setInfosContato(
            {
              ...atendimentosAbertos[index].tabContato,
              infosChamada: {
                ...atendimentosAbertos[index].tabContato.infosChamada,
                tabulacaoChamada: null,
                dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
                horarioAgendamento: moment().format('HH:mm'),
                tipoAgendamento: '',
                observacoesAgendamento: '',
                numerosDiscados: telefonesDiscados ?? [],
                modalDiscagem: telefonesDiscados?.length > 0
              }
            },
            removeMaskCPF(atendimentosAbertos[index].cpf)
          )
        )
      } else {
        yield put(
          atendimentoActions.setInfosContato(
            {
              ...atendimentosAbertos[index].tabContato,
              infosChamada: {
                ...atendimentosAbertos[index].tabContato.infosChamada,
                numeroChamada: '',
                tabulacaoChamada: null,
                dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
                horarioAgendamento: moment().format('HH:mm'),
                tipoAgendamento: '',
                observacoesAgendamento: '',
                emChamada: false,
                statusChamada: 0,
                modalDiscagem: false,
                numerosDiscados: []
              }
            },
            removeMaskCPF(atendimentosAbertos[index].cpf)
          )
        )
      }
      if (
        response?.data?.content?.telefones?.length >= 0 ||
        response?.data?.content?.telefones === null
      ) {
        const telefones: InfosChamada['telefones'] = []
        response?.data?.content?.telefones?.forEach((telefone: Telefone) => {
          telefones.push({
            numero: `(${telefone.ddd}) ${telefone.numero}`,
            whatsapp: telefone.whatsapp,
            telefoneId: telefone.telefoneId
          })
        })
        yield put(
          atendimentoActions.setInfosContato(
            {
              ...atendimentosAbertos[index].tabContato,
              infosChamada: {
                ...atendimentosAbertos[index].tabContato.infosChamada,
                telefones: telefones
              }
            },
            removeMaskCPF(atendimentosAbertos[index].cpf)
          )
        )
        if (response?.data?.content?.proximoTelefone && telefones?.length > 0) {
          yield put(
            atendimentoActions.discarClienteSaga(
              index,
              telefones?.[0].telefoneId
            )
          )
        }
        if (response?.data?.content?.telefones?.length === 0) {
          yield put(atendimentoActions.validarNovoAtendimentoSaga(cpf, index))
        }
      }
      yield put(
        atendimentoActions.getAtendimentosSaga(
          atendimentosAbertos[index].cpf,
          index
        )
      )
      toast.success('Tabulação realizada com sucesso!')
    }
  } catch (error) {
    handlerError(error, 'Não foi possível tabular o atendimento!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getPropostasCrm({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  let clienteNaoElegivel = false
  let mensagemModalIndicacao = ''
  try {
    yield put(atendimentoActions.setLoadingSimulacao(true, index))
    yield put(
      atendimentoActions.setInfosProduto([], 'produtosDisponiveis', index)
    )
    yield put(
      atendimentoActions.setInfosProduto([], 'produtosSelecionados', index)
    )
    const cpfFormated = removeMaskCPF(cpf)
    let response: ProdutosSimulacao[] = []
    const getSimulacoes = function* (): Generator<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      AllEffect<Generator<CallEffect<AxiosResponse<any, any>>, any, unknown>>,
      void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      AxiosResponse<ReqCrmSimulacao<any>, any>[]
    > {
      const filteredBeneficios = atendimentosAbertos[
        index
      ].tabProduto.beneficios?.filter(
        (beneficio) => beneficio.clientePerfil.tipoPerfil !== 2
      )
      const responses = yield all(
        filteredBeneficios.map(function* (beneficio) {
          try {
            const response: AxiosResponse<
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ReqCrmSimulacao<any>,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              any
            > = yield call(
              atendimentoController.getPropostasCrm,
              cpfFormated,
              beneficio.numero
            )
            return response
          } catch (error) {
            handlerErrorSimulacao(
              error,
              'Houve um erro ao realizar a simulação, tente novamente mais tarde.'
            )
            return null
          }
        })
      )
      responses?.map((res) => {
        if (Array.isArray(res?.data?.content)) {
          response = response.concat(res?.data?.content)
        }
        if (res?.data?.messages && res?.data?.messages?.length > 0) {
          handlerErrorSimulacao(res?.data)
        }

        if (res?.data?.modalElegibilidade) {
          clienteNaoElegivel = true
          mensagemModalIndicacao = res?.data?.content?.mensagemModal
        }
        if (res?.data?.content?.modalInegibilidade) {
          clienteNaoElegivel = true
          mensagemModalIndicacao = res?.data?.content?.mensagemInegibilidade
        }
      })
    }

    yield call(getSimulacoes)
    let produtosDisponiveis: ProdutosSimulacao[] = []
    const codigoPlanoConsignado: {
      matricula: string
      value: number
      valorPremio: number
    }[] = []

    const codigoPlanoBeneficio: {
      matricula: string
      value: number
      valorPremio: number
    }[] = []
    const codigoPlanoCartaoBeneficio: {
      matricula: string
      value: number
      valorPremio: number
    }[] = []
    const codigoPlanoCartaoConsignado: {
      matricula: string
      value: number
      valorPremio: number
      flagSaqueParcelado: boolean
    }[] = []
    let contratoPortabilidadeFormatted: ProdutosSimulacao['contratosPortabilidade'] =
      []

    for (const produto of response) {
      const maiorValorPremioSeguroPrestamista =
        produto.planosSeguroPrestamista?.planosComLimiteSaque?.reduce(
          (max: number, plano) => {
            return plano.valorPremio > max ? plano.valorPremio : max
          },
          produto.planosSeguroPrestamista?.planosComLimiteSaque[0].valorPremio
        )
      if (maiorValorPremioSeguroPrestamista !== undefined) {
        const plano =
          produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
            (plano) => plano.valorPremio === maiorValorPremioSeguroPrestamista
          )

        if (produto.servico === 5) {
          codigoPlanoConsignado.push({
            matricula: produto.matricula,
            value: plano?.codigoPlano ?? 0,
            valorPremio: maiorValorPremioSeguroPrestamista ?? 0
          })
        }
        if (produto.servico === 6) {
          codigoPlanoCartaoConsignado.push({
            matricula: produto.matricula,
            value: plano?.codigoPlano ?? 0,
            valorPremio: maiorValorPremioSeguroPrestamista ?? 0,
            flagSaqueParcelado: true
          })
        }
        if (produto.servico === 7) {
          codigoPlanoCartaoBeneficio.push({
            matricula: produto.matricula,
            value: plano?.codigoPlano ?? 0,
            valorPremio: maiorValorPremioSeguroPrestamista ?? 0
          })
        }

        if (produto.servico === 8) {
          codigoPlanoBeneficio.push({
            matricula: produto.matricula,
            value: plano?.codigoPlano ?? 0,
            valorPremio: maiorValorPremioSeguroPrestamista ?? 0
          })
        }
      }

      if (produto.servico === 3) {
        contratoPortabilidadeFormatted = produto.contratosPortabilidade?.map(
          (contratoPortabilidade) => ({
            ...contratoPortabilidade,
            listProdutosPortabilidade:
              contratoPortabilidade.listProdutosPortabilidade.map(
                (produtoPortabilidade, produtoPortabilidadeIndex) => ({
                  ...produtoPortabilidade,
                  produtosAssociados:
                    produtoPortabilidade.produtosAssociados.map(
                      (produtoAssociado, produtoAssociadoIndex) => ({
                        ...produtoAssociado,
                        selecionado: produtoAssociadoIndex === 0
                      })
                    ),
                  selecionado: produtoPortabilidadeIndex === 0
                })
              )
          })
        )
      }

      produtosDisponiveis.push({
        banco: produto.banco,
        codigoFator: produto.codigoFator,
        codigoProduto: produto.codigoProduto,
        codigoSimulacao: produto.codigoSimulacao,
        clienteElegivel: produto.clienteElegivel,
        contratoRefinanciamento: produto.contratoRefinanciamento,
        erros: produto.erros,
        cpf: produto.cpf,
        id: produto.id,
        listProdutos: produto.listProdutos,
        listParcelas: produto.listParcelas,
        mensagemOperadora: produto.mensagemOperadora,
        matricula: produto.matricula,
        perfil: produto.perfil,
        porcentagemFator: produto.porcentagemFator,
        quantidadeParcelas: produto.quantidadeParcelas,
        servico: produto.servico,
        valorDesejado: produto.valorDesejado,
        valorLimite: produto.valorLimite,
        valorParcela: produto.valorParcela,
        valorLiberado: produto.valorTotalFinanciado,
        valorSaqueMaximo: produto.valorSaqueMaximo,
        valorSaqueMinimo: produto.valorSaqueMinimo,
        valorTotalFinanciado: produto.valorTotalFinanciado,
        flagSaqueAutorizado:
          produto.servico === 5 ||
          produto.servico === 8 ||
          produto.servico === 12, //Desabilitando campo do cartão benefício até que seja integrado: produto.servico === 5 || produto.servico === 6 || produto.servico === 7 || produto.servico === 8
        flagSaqueParcelado: produto.servico === 7 || produto.servico === 6,
        valorSaque: maskMoney(Number(produto.valorSaque).toFixed(2)),
        flagAberturaConta: false,
        bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
        margemDisponivel: produto.margemDisponivel,
        margemDisponivelCartao: produto.margemDisponivelCartao,
        margemDisponivelCartaoBeneficio:
          produto.margemDisponivelCartaoBeneficio,
        produtosSINDNAP: produto.produtosSINDNAP,
        tipoProduto: produto.tipoProduto,
        numeroContaInterna: produto.numeroContaInterna,
        permiteAgregacaoRefin: produto.permiteAgregacaoRefin,
        valorMargem: produto.valorMargem,
        limiteCartao: produto.limiteCartao,
        limiteDisponivel: produto.limiteDisponivel,
        planosSeguroPrestamista: produto.planosSeguroPrestamista,
        tipoSaque: produto.tipoSaque,
        modalInegibilidade: produto.modalInegibilidade,
        mensagemInegibilidade: produto.mensagemInegibilidade,
        contratosPortabilidade: contratoPortabilidadeFormatted
      })
    }

    yield put(
      atendimentoActions.setInfosProduto(
        codigoPlanoConsignado,
        'listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        codigoPlanoBeneficio,
        'listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        codigoPlanoCartaoBeneficio,
        'listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosProduto(
        codigoPlanoCartaoConsignado,
        'listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula',
        index
      )
    )
    const produtosSindnapi = response.filter(
      (produto: ProdutosSimulacao) => produto.servico === 12
    )
    let produtoMaiorValor: ProdutosSimulacao | null = null
    let maiorValor = 0
    produtosSindnapi.map((produto: ProdutosSimulacao) => {
      if (
        (produto.produtosSINDNAP?.planos?.[0]?.valorPremioAux ?? 0) > maiorValor
      ) {
        maiorValor = produto.produtosSINDNAP?.planos[0].valorPremioAux ?? 0
        produtoMaiorValor = produto
      }
    })
    if (produtoMaiorValor) {
      produtosDisponiveis = produtosDisponiveis?.filter(
        (produto) => produto.servico !== 12
      )
      produtosDisponiveis.push(produtoMaiorValor)
    }

    if (
      produtosDisponiveis.some(
        (produto) =>
          produto.modalInegibilidade &&
          ((produto.servico === 1 && produto.id === null) ||
            (produto.servico === 2 && produto.id === null))
      )
    ) {
      yield put(
        atendimentoActions.setInfosProduto(true, 'modalInegibilidade', index)
      )

      const mensagensInegibilidade = produtosDisponiveis
        .filter(
          (produto) =>
            produto.modalInegibilidade &&
            (produto.servico === 1 || produto.servico === 2)
        )
        .map((produto) => produto.mensagemInegibilidade)

      yield put(
        atendimentoActions.setInfosProduto(
          mensagensInegibilidade,
          'mensagemInegibilidade',
          index
        )
      )

      produtosDisponiveis = produtosDisponiveis.filter(
        (produto) =>
          !(
            produto.modalInegibilidade &&
            ((produto.servico === 1 && produto.id === null) ||
              (produto.servico === 2 && produto.id === null))
          )
      )
    }

    yield put(
      atendimentoActions.setInfosProduto(
        [...produtosDisponiveis],
        'produtosDisponiveis',
        index
      )
    )

    const newProdutosDisponiveis = [
      ...produtosDisponiveis.filter((produto) => {
        return !(
          ((produto.servico === 5 || produto.servico === 8) &&
            produto.valorTotalFinanciado === 0) ||
          (produto.servico === 2 && produto.clienteElegivel === false)
        )
      })
    ]

    const margemDisponivelByMatricula: {
      matricula: string
      value: number
    }[] = []

    const listProdutosRefinQtdDeParcelasOriginaisByContrato: {
      contrato: string
      value: number
    }[] = []

    newProdutosDisponiveis.forEach((produto) => {
      if (produto.servico === 2 && produto.matricula) {
        if (produto.contratoRefinanciamento) {
          listProdutosRefinQtdDeParcelasOriginaisByContrato.push({
            contrato:
              produto.contratoRefinanciamento.numeroOperacaoCreditoExterno,
            value: produto.quantidadeParcelas
          })
        }

        const isMatriculaAlreadyAdded = margemDisponivelByMatricula.some(
          (item) => item.matricula === produto.matricula
        )

        if (isMatriculaAlreadyAdded) return

        margemDisponivelByMatricula.push({
          matricula: produto.matricula,
          value: produto.margemDisponivel
        })
      }
    })

    const listCodigoTabelaSelectedByCodigoSimulacao: {
      id: number
      value: number
    }[] = []
    const listParcelasSelectedByCodigoSimulacao: {
      id: number
      value: number
    }[] = []

    newProdutosDisponiveis.forEach((produto) => {
      if (
        produto.servico === 2 &&
        produto.clienteElegivel &&
        produto.codigoSimulacao
      ) {
        listCodigoTabelaSelectedByCodigoSimulacao.push({
          id: produto.codigoSimulacao,
          value: produto.listProdutos[0].codigo
        })

        const listParcelas = produto.listProdutos[0].listParcelas

        const newParcelasSelected = listParcelas?.reduce(
          (max: number, parcela: ListaParcelasEValorTotalSimulacao) => {
            return parcela.parcelas > max ? parcela.parcelas : max
          },
          listParcelas[0].parcelas
        )

        listParcelasSelectedByCodigoSimulacao.push({
          id: produto.codigoSimulacao,
          value: newParcelasSelected
        })
      }
    })

    yield put(
      atendimentoActions.setInfosProduto(
        margemDisponivelByMatricula,
        'margemDisponivelByMatricula',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        listProdutosRefinQtdDeParcelasOriginaisByContrato,
        'listProdutosRefinQtdDeParcelasOriginaisByContrato',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        listCodigoTabelaSelectedByCodigoSimulacao,
        'listCodigoTabelaSelectedByCodigoSimulacao',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        listParcelasSelectedByCodigoSimulacao,
        'listParcelasSelectedByCodigoSimulacao',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        newProdutosDisponiveis,
        'produtosSelecionados',
        index
      )
    )
  } catch (error) {
    handlerErrorSimulacao(
      error,
      'Houve um erro ao realizar a simulação, tente novamente mais tarde.'
    )
  } finally {
    yield put(atendimentoActions.setLoadingSimulacao(false, index))
    if (
      atendimentosAbertos[index].tabProduto.beneficios.find(
        (Beneficio) => Beneficio.clientePerfil.tipoPerfil === 2
      ) &&
      !clienteNaoElegivel
    ) {
      yield put(atendimentoActions.solicitarSimulacaoFgtsSaga(cpf, index))
    }
  }
  if (clienteNaoElegivel) {
    yield put(
      atendimentoActions.setInfosProduto(
        mensagemModalIndicacao,
        'mensagemModalIndicacao',
        index
      )
    )
    yield put(atendimentoActions.setInfosProduto(true, 'modalIndicacao', index))
  }
}

function* solicitarSimulacaoFgts({ cpf, index }: GenericProps): SagaIterator {
  try {
    yield put(atendimentoActions.setLoadingSimulacao(true, index))
    const cpfFormated = removeMaskCPF(cpf)
    yield call(atendimentoController.solicitarSimulacaoFgts, cpfFormated)
  } catch (error) {
    handlerErrorSimulacaoFgts(
      error,
      'Houve um erro ao solicitar a simulação do FGTS, tente novamente mais tarde.'
    )
  } finally {
    yield put(atendimentoActions.setLoadingSimulacao(false, index))
    yield put(atendimentoActions.getParcelasFgtsSaga(cpf, index))
  }
}

function* getParcelasFgts({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(atendimentoActions.setLoadingSimulacao(true, index))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.getParcelaFgts,
      cpfFormated
    )
    const produtosDisponiveis = [
      ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
    ]
    const produtosSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]

    const iDisponivel = produtosDisponiveis.findIndex(
      (produto) => produto.servico === 9
    )
    const iSelecionado = produtosSelecionados.findIndex(
      (produto) => produto.servico === 9
    )
    if (iDisponivel !== -1) {
      produtosDisponiveis.splice(iDisponivel, 1)
    }
    if (iSelecionado !== -1) {
      produtosSelecionados.splice(iSelecionado, 1)
    }

    if (response?.data?.content && response?.data?.content.hoje) {
      const produtoFgts: ProdutosSimulacao = {
        id: 'fgts',
        perfil: 1,
        quantidadeParcelas: Number(response?.data?.content?.quantidadeParcelas),
        servico: 9,
        valorTotalFinanciado: Number(response?.data?.content?.valorLiberado),
        parcelaFgtsItem: response?.data?.content?.parcelaFgtsItem,
        banco: 'BMG',
        codigoFator: '',
        codigoProduto: null,
        cpf: cpfFormated,
        listProdutos: [],
        listParcelas: [],
        mensagemOperadora: null,
        matricula: '0',
        porcentagemFator: 0,
        valorDesejado: 0,
        valorLimite: 0,
        valorParcela: 0,
        valorLiberado: 0,
        valorSaqueMaximo: 0,
        valorSaqueMinimo: 0,
        flagSaqueAutorizado: false,
        flagSaqueParcelado: false,
        flagAberturaConta: false,
        bancoSimulacaoEnum: 0,
        margemDisponivel: 0,
        margemDisponivelCartao: 0,
        margemDisponivelCartaoBeneficio: 0,
        tipoProduto: 0,
        valorSaque: maskMoney(0),
        produtosSINDNAP: undefined,
        numeroContaInterna: null,
        clienteElegivel: false,
        contratoRefinanciamento: undefined,
        permiteAgregacaoRefin: false,
        valorMargem: 0,
        limiteCartao: 0,
        limiteDisponivel: 0,
        tipoSaque: 0
      }
      produtosDisponiveis.push(produtoFgts)
      produtosSelecionados.push(produtoFgts)
    }
    yield put(
      atendimentoActions.setInfosProduto(
        produtosDisponiveis,
        'produtosDisponiveis',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosProduto(
        produtosSelecionados,
        'produtosSelecionados',
        index
      )
    )
  } catch (error) {
    handlerErrorSimulacaoFgts(
      error,
      'Houve um erro ao obter a oferta do FGTS, tente novamente mais tarde.'
    )
  } finally {
    yield put(atendimentoActions.setLoadingSimulacao(false, index))
  }
}

interface RecalculoPropostaProps extends GenericProps {
  numeroMatricula: string
  quantidadeparcelas: number
  valorTotalFinanciado: number
  valorParcela: number
  servico: number
  codigoProduto: number
  banco: number
  numeroProposta: string | null
  taxaOrigem: number | null
  quantidadeTotalParcelas: number
  saldoDevedor: number | null
  indexPortabilidade: number | null
  indexRefinPortabilidade: number | null
  codigoBanco: string | null
}

export function* recalculoProposta({
  cpf,
  numeroMatricula,
  quantidadeparcelas,
  valorTotalFinanciado,
  valorParcela,
  servico,
  codigoProduto,
  banco,
  numeroProposta,
  taxaOrigem,
  quantidadeTotalParcelas,
  saldoDevedor,
  index,
  indexPortabilidade,
  indexRefinPortabilidade,
  codigoBanco
}: RecalculoPropostaProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(() =>
      atendimentoController.recalculoProposta(
        cpfFormated,
        numeroMatricula,
        quantidadeparcelas,
        valorTotalFinanciado,
        valorParcela,
        servico,
        codigoProduto,
        banco,
        numeroProposta || null,
        taxaOrigem || null,
        quantidadeTotalParcelas,
        saldoDevedor || null,
        codigoBanco || null
      )
    )
    const produtosDisponiveis = [
      ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
    ]
    const produtosSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]
    const indexDisponiveis = produtosDisponiveis.findIndex(
      (produto) =>
        produto.servico === response?.data?.content?.servico &&
        produto.matricula === response?.data?.content?.matricula
    )
    const indexSelecionados = produtosSelecionados.findIndex(
      (produto) =>
        produto.servico === response?.data?.content?.servico &&
        produto.matricula === response?.data?.content?.matricula
    )
    if (servico !== 3) {
      if (indexDisponiveis !== -1) {
        produtosDisponiveis[indexDisponiveis] = {
          ...produtosDisponiveis[indexDisponiveis],
          ...response?.data?.content,
          valorSaque: maskMoney(response?.data?.content?.valorSaque?.toFixed(2))
        }
      }
      if (indexSelecionados !== -1) {
        produtosSelecionados[indexSelecionados] = {
          ...produtosDisponiveis[indexDisponiveis],
          ...response?.data?.content,
          valorSaque: maskMoney(response?.data?.content?.valorSaque?.toFixed(2))
        }
      }
    }
    if (servico === 3) {
      if (indexDisponiveis !== -1) {
        const contratos =
          produtosDisponiveis[indexDisponiveis].contratosPortabilidade
        const indexContrato = produtosDisponiveis[
          indexDisponiveis
        ].contratosPortabilidade?.findIndex(
          (contratoPortabilidade) =>
            maskNumber(contratoPortabilidade.numeroProposta) ===
            maskNumber(numeroProposta)
        )
        if (contratos) {
          if (indexContrato !== -1) {
            const contrato = contratos[indexContrato ?? 0]
            const listProdutosPortabilidade =
              contrato.listProdutosPortabilidade || []

            if (listProdutosPortabilidade[indexPortabilidade ?? 0]) {
              const produtoPortabilidade =
                listProdutosPortabilidade[indexPortabilidade ?? 0]
              const produtosAssociados =
                produtoPortabilidade.produtosAssociados || []

              produtosAssociados[indexRefinPortabilidade ?? 0] = {
                ...response?.data?.content?.contratosPortabilidade?.[0]
                  .listProdutosPortabilidade?.[0].produtosAssociados?.[0],
                selecionado:
                  produtosAssociados[indexRefinPortabilidade ?? 0].selecionado
              }

              produtoPortabilidade.produtosAssociados = produtosAssociados

              listProdutosPortabilidade[indexPortabilidade ?? 0] = {
                ...response?.data?.content?.contratosPortabilidade?.[0]
                  .listProdutosPortabilidade?.[0],
                produtosAssociados: produtosAssociados,
                selecionado:
                  listProdutosPortabilidade[indexPortabilidade ?? 0].selecionado
              }
            }
            if (
              (indexContrato || indexContrato === 0) &&
              produtosDisponiveis &&
              produtosDisponiveis[indexDisponiveis] &&
              produtosDisponiveis[indexDisponiveis].contratosPortabilidade &&
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              produtosDisponiveis[indexDisponiveis].contratosPortabilidade![
                indexContrato
              ]
            ) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              produtosDisponiveis[indexDisponiveis].contratosPortabilidade![
                indexContrato
              ] = {
                ...response?.data?.content?.contratosPortabilidade?.[0],
                listProdutosPortabilidade: listProdutosPortabilidade
              }
            }
          }
        }
      }
      if (indexSelecionados !== -1) {
        const contratos =
          produtosSelecionados[indexSelecionados].contratosPortabilidade
        const indexContrato = produtosSelecionados[
          indexSelecionados
        ].contratosPortabilidade?.findIndex(
          (contratoPortabilidade) =>
            maskNumber(contratoPortabilidade.numeroProposta) ===
            maskNumber(numeroProposta)
        )
        if (contratos) {
          if (indexContrato !== -1) {
            const contrato = contratos[indexContrato ?? 0]
            const listProdutosPortabilidade =
              contrato.listProdutosPortabilidade || []

            if (listProdutosPortabilidade[indexPortabilidade ?? 0]) {
              const produtoPortabilidade =
                listProdutosPortabilidade[indexPortabilidade ?? 0]
              const produtosAssociados =
                produtoPortabilidade.produtosAssociados || []

              produtosAssociados[indexRefinPortabilidade ?? 0] = {
                ...response?.data?.content?.contratosPortabilidade?.[0]
                  .listProdutosPortabilidade?.[0].produtosAssociados?.[0],
                selecionado:
                  produtosAssociados[indexRefinPortabilidade ?? 0].selecionado
              }

              produtoPortabilidade.produtosAssociados = produtosAssociados

              listProdutosPortabilidade[indexPortabilidade ?? 0] = {
                ...response?.data?.content?.contratosPortabilidade?.[0]
                  .listProdutosPortabilidade?.[0],
                produtosAssociados: produtosAssociados,
                selecionado:
                  listProdutosPortabilidade[indexPortabilidade ?? 0].selecionado
              }
            }
            if (
              (indexContrato || indexContrato === 0) &&
              produtosSelecionados &&
              produtosSelecionados[indexSelecionados] &&
              produtosSelecionados[indexSelecionados].contratosPortabilidade &&
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              produtosSelecionados[indexSelecionados].contratosPortabilidade![
                indexContrato
              ]
            ) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              produtosSelecionados[indexSelecionados].contratosPortabilidade![
                indexContrato
              ] = {
                ...response?.data?.content?.contratosPortabilidade?.[0],
                listProdutosPortabilidade: listProdutosPortabilidade
              }
            }
          }
        }
      }
    }
    yield put(
      atendimentoActions.setInfosProduto(
        produtosDisponiveis,
        'produtosDisponiveis',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosProduto(
        produtosSelecionados,
        'produtosSelecionados',
        index
      )
    )
    if (response?.data?.messages?.length > 0) {
      handlerError(response?.data)
    }
    toast.success('Recálculo realizado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível recalcular!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}
interface RecalculoPropostaFgtsProps extends GenericProps {
  cpf: string
  valor: number
  parcelas: number
}

function* recalculoPropostaFgts({
  cpf,
  valor = 0,
  parcelas,
  index
}: RecalculoPropostaFgtsProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    yield call(
      atendimentoController.recalculoPropostaFgts,
      cpfFormated,
      valor,
      parcelas
    )
    const response = yield call(
      atendimentoController.getParcelaFgts,
      cpfFormated
    )

    const produtosDisponiveis = [
      ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
    ]
    const produtosSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]

    if (response?.data?.content) {
      const produtoFgts: ProdutosSimulacao = {
        id: 'fgts',
        perfil: 1,
        quantidadeParcelas: Number(response?.data?.content?.quantidadeParcelas),
        servico: 9,
        valorTotalFinanciado: Number(response?.data?.content?.valorLiberado),
        banco: 'BMG',
        codigoFator: '',
        codigoProduto: null,
        cpf: cpfFormated,
        listProdutos: [],
        listParcelas: [],
        mensagemOperadora: null,
        matricula: '0',
        porcentagemFator: 0,
        valorDesejado: 0,
        valorLimite: 0,
        valorParcela: 0,
        valorLiberado: 0,
        valorSaqueMaximo: 0,
        valorSaqueMinimo: 0,
        valorSaque: maskMoney(0),
        flagSaqueAutorizado: false,
        flagSaqueParcelado: false,
        flagAberturaConta: false,
        bancoSimulacaoEnum: 0,
        margemDisponivel: 0,
        margemDisponivelCartao: 0,
        margemDisponivelCartaoBeneficio: 0,
        tipoProduto: 0,
        produtosSINDNAP: undefined,
        numeroContaInterna: null,
        clienteElegivel: false,
        permiteAgregacaoRefin: false,
        valorMargem: 0,
        limiteCartao: 0,
        limiteDisponivel: 0,
        tipoSaque: 0
      }
      const indexDisponiveis = produtosDisponiveis.findIndex(
        (produto) => produto.servico === produtoFgts.servico
      )
      const indexSelecionados = produtosSelecionados.findIndex(
        (produto) => produto.servico === produtoFgts.servico
      )
      if (indexDisponiveis !== -1) {
        produtosDisponiveis[indexDisponiveis] = {
          ...produtosDisponiveis[indexDisponiveis],
          ...produtoFgts
        }
      }
      if (indexSelecionados !== -1) {
        produtosSelecionados[indexSelecionados] = {
          ...produtosSelecionados[indexSelecionados],
          ...produtoFgts
        }
      }
    }

    yield put(
      atendimentoActions.setInfosProduto(
        produtosDisponiveis,
        'produtosDisponiveis',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosProduto(
        produtosSelecionados,
        'produtosSelecionados',
        index
      )
    )
    toast.success('Recálculo realizado com sucesso!')
  } catch (error) {
    handlerErrorSimulacaoFgts(
      error,
      'Houve um erro ao realizar o recálculo do FGTS, tente novamente mais tarde.'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getResumoContratacao({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const matriculasContratacao = atendimentosAbertos[
      index
    ].tabProduto.produtosSelecionados.map((produto) => {
      return produto.matricula
    })
    const response = yield call(
      atendimentoController.getResumoContratacao,
      cpfFormated,
      [...new Set(matriculasContratacao)]
    )

    const infosClienteResumo: InfosClienteResumo = {
      bairro: response?.data?.content?.bairro,
      cep: response?.data?.content?.cep,
      cidade: response?.data?.content?.cidade,
      cidadeNascimento: response?.data?.content?.cidadeNascimento,
      complemento: response?.data?.content?.complemento,
      cpf: maskCPF(response?.data?.content?.cpf),
      dataEmissao: moment(response?.data?.content?.dataEmissao).format(
        'DD/MM/YYYY'
      ),
      dataNascimento: moment(response?.data?.content?.dataNascimento).format(
        'DD/MM/YYYY'
      ),
      emissorIdentidade: response?.data?.content?.emissorIdentidade,
      estadoCivil: response?.data?.content?.estadoCivil,
      grauInstrucao: response?.data?.content?.grauInstrucao,
      matriculas: response?.data?.content?.matriculas.map(
        (matricula: MatriculaResumo) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemDisponivel: matricula.margemDisponivel,
            margemCartaoDisponivel: matricula.margemCartaoDisponivel,
            margemCartaoBeneficio: matricula.margemCartaoBeneficio,
            descricaoBanco: matricula.descricaoBanco,
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia,
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta,
            digitoVerificadorConta: matricula.digitoVerificadorConta
          }
        }
      ),
      nacionalidade: response?.data?.content?.nacionalidade,
      nome: response?.data?.content?.nome,
      nomeConjuge: response?.data?.content?.nomeConjuge,
      nomeMae: response?.data?.content?.nomeMae,
      nomePai: response?.data?.content?.nomePai,
      numeroEndereco: response?.data?.content?.numeroEndereco,
      numeroIdentidade: response?.data?.content?.numeroIdentidade,
      sexo: response?.data?.content?.sexo,
      uf: response?.data?.content?.uf,
      ufIdentidade: response?.data?.content?.ufIdentidade,
      ufNascimento: response?.data?.content?.ufNascimento,
      logradouro: response?.data?.content?.logradouro,
      numero: response?.data?.content?.numero,
      ddd: response?.data?.content?.ddd
    }

    yield put(
      atendimentoActions.setInfosProduto(
        infosClienteResumo,
        'infosClienteResumo',
        index
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível carregar as informações do cliente!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getSolicitacoesIN100({ cpf }: GenericProps): SagaIterator {
  try {
    const cpfFormated = removeMaskCPF(cpf)
    yield call(atendimentoController.getSolicitacoesIN100, cpfFormated)
  } catch (error) {
    //
  }
}

function* getTelefones({ cpf, index }: GenericProps): SagaIterator {
  try {
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(atendimentoController.getTelefones, cpfFormated)
    const telefonesFormated = response?.data?.content.map(
      (telefone: { ddd: string; numero: string }) => {
        return {
          label: maskPhoneWithDDD(telefone.ddd + telefone.numero),
          value: maskPhoneWithDDD(telefone.ddd + telefone.numero)
        }
      }
    )
    yield put(
      atendimentoActions.setInfosProduto(
        telefonesFormated,
        'telefonesClienteIN100',
        index
      )
    )
  } catch (error) {
    //
  }
}

interface InserirSolicitacaoIN100Props extends GenericProps {
  telefone: string
  matricula: string
}

function* inserirSolicitacaoIN100({
  cpf,
  telefone,
  matricula,
  index
}: InserirSolicitacaoIN100Props): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    yield call(
      atendimentoController.inserirSolicitacaoIN100,
      cpfFormated,
      telefone,
      matricula
    )
    const responseTelefones = yield call(
      atendimentoController.getTelefonesValidos,
      atendimentosAbertos[index].cpf
    )
    const telefones: InfosChamada['telefones'] = []
    responseTelefones?.data?.content?.forEach((telefone: Telefone) => {
      telefones.push({
        numero: maskPhoneWithDDD(telefone.ddd + telefone.numero),
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContatoField(
        {
          ...atendimentosAbertos[index].tabContato.infosChamada,
          telefones: telefones
        },
        'infosChamada',
        index
      )
    )
    yield put(
      atendimentoActions.getInformacoesClienteSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )

    toast.success('Solicitação enviada com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível enviar a solicitação!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface validaStatusIN100Props extends GenericProps {
  matricula: string
  indexBeneficio: number
}

function* validaStatusIN100({
  cpf,
  matricula,
  index,
  indexBeneficio
}: validaStatusIN100Props): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )

  try {
    yield put(
      atendimentoActions.setLoadingBeneficio(true, index, indexBeneficio)
    )

    const cpfFormated = removeMaskCPF(cpf)
    let success = false

    const res = yield call(
      atendimentoController.validaStatusIN100,
      cpfFormated,
      matricula
    )

    if (
      res?.data?.content?.success &&
      res?.data?.content?.message === 'IN100 atualizada com sucesso'
    ) {
      success = true
      toast.success(res?.data?.content?.message)

      yield put(
        atendimentoActions.getInformacoesClienteSaga(
          atendimentosAbertos[index].cpf,
          index
        )
      )

      const beneficios = [...atendimentosAbertos[index].tabProduto.beneficios]
      beneficios[indexBeneficio] = {
        ...beneficios[indexBeneficio],
        dataAtualizacaoToken: res?.data?.content?.dataAtualizacaoToken,
        dataConsentimento: res?.data?.content?.dataConsentimento,
        dataRespostaIn100: res?.data?.content?.dataRespostaIn100
      }

      yield put(
        atendimentoActions.setInfosProduto(beneficios, 'beneficios', index)
      )
    } else if (res?.data?.content?.message === 'Aguardando retorno da IN100') {
      let loop = true

      do {
        yield delay(30000)

        const retryRes = yield call(
          atendimentoController.validaStatusIN100,
          cpfFormated,
          matricula
        )

        if (
          retryRes?.data?.content?.success &&
          retryRes?.data?.content?.message === 'IN100 atualizada com sucesso'
        ) {
          success = true
          loop = false
          toast.success(retryRes?.data?.content?.message)

          yield put(
            atendimentoActions.getInformacoesClienteSaga(
              atendimentosAbertos[index].cpf,
              index
            )
          )

          const beneficios = [
            ...atendimentosAbertos[index].tabProduto.beneficios
          ]
          beneficios[indexBeneficio] = {
            ...beneficios[indexBeneficio],
            dataAtualizacaoToken: retryRes?.data?.content?.dataAtualizacaoToken,
            dataConsentimento: retryRes?.data?.content?.dataConsentimento,
            dataRespostaIn100: retryRes?.data?.content?.dataRespostaIn100
          }

          yield put(
            atendimentoActions.setInfosProduto(beneficios, 'beneficios', index)
          )
        } else if (
          retryRes?.data?.content?.message !== 'Aguardando retorno da IN100'
        ) {
          toast.error(retryRes?.data?.content?.message || 'Erro inesperado')
          loop = false
        }
      } while (loop && !success)
    } else {
      toast.error(res?.data?.content?.message || 'Erro inesperado')
    }
  } catch (error) {
    handlerError(error, 'Não foi possível! Tente novamente mais tarde.')
  } finally {
    yield put(
      atendimentoActions.setLoadingBeneficio(false, index, indexBeneficio)
    )
  }
}

interface ValidacaoIN100Props extends GenericProps {
  token: string
  matricula: string
}

function* validacaoIN100({
  cpf,
  matricula,
  token
}: ValidacaoIN100Props): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    yield call(
      atendimentoController.validacaoIN100,
      cpfFormated,
      matricula,
      token
    )
    toast.success('Código validado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível validar o código!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface ConsentimentoItauProps extends GenericProps {
  telefone: string
  matricula: string
  canal: string
}

function* consentimentoItau({
  cpf,
  telefone,
  canal,
  matricula
}: ConsentimentoItauProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.consentimentoItau,
      cpfFormated,
      telefone,
      canal,
      matricula
    )
    toast.success(response?.data?.content)
  } catch (error) {
    handlerError(
      error,
      'Não foi possível realizar a consulta/envio da coleta de consentimento Itaú!'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface consultaConsentimentoItauProps extends GenericProps {
  cpf: string
  matricula: string
  indexBeneficio: number
}

function* consultaConsentimentoItau({
  cpf,
  matricula,
  index,
  indexBeneficio
}: consultaConsentimentoItauProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.ConsultaConsentimentoItau,
      cpfFormated,
      matricula
    )
    const beneficios = [...atendimentosAbertos[index].tabProduto.beneficios]
    beneficios[indexBeneficio] = {
      ...beneficios[indexBeneficio],
      dataAtualizacaoToken: response?.data?.content?.dataAtualizacaoToken,
      dataConsentimento: response?.data?.content?.dataConsentimento,
      dataRespostaIn100: response?.data?.content?.dataRespostaIn100
    }
    toast.success(response?.data?.content)
  } catch (error) {
    handlerError(
      error,
      'Não foi possível realizar a consulta/envio da coleta de consentimento Itaú!'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getProdutosContratados({ cpf, index }: GenericProps): SagaIterator {
  try {
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.getProdutosContratados,
      cpfFormated
    )

    const produtosContratados: ProdutosContratados[] = []

    response?.data?.content?.forEach((produto: ProdutosContratados) => {
      produtosContratados.push({
        cpf: produto.cpf,
        nomeCliente: produto.nomeCliente,
        numeroProposta: produto.numeroProposta,
        produto: produto.produto,
        valorTotal: produto.valorTotal,
        quantidadeParcelas: produto.quantidadeParcelas,
        valorParcela: produto.valorParcela,
        valorLiberado: produto.valorTotal,
        usuarioInstituicao: produto.usuarioInstituicao,
        status: produto.status,
        banco: produto.banco,
        numeroMatricula: produto.numeroMatricula,
        id: produto.id,
        ativo: produto.ativo,
        usuario: produto.usuario,
        idUsuarioCriacao: produto.idUsuarioCriacao,
        dataCriacao: produto.dataCriacao,
        dataContratacao: produto.dataContratacao,
        usuarioAlteracao: produto.usuarioAlteracao,
        idUsuarioUltimaAlteracao: produto.idUsuarioUltimaAlteracao,
        dataUltimaAlteracao: produto.dataUltimaAlteracao
      })
    })
    yield put(
      atendimentoActions.setInfosProduto(
        produtosContratados,
        'produtosContratados',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* validacaoContratacao({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const matriculasContratacao = atendimentosAbertos[
      index
    ].tabProduto.produtosSelecionados.map((produto) => {
      return produto.matricula
    })
    const response = yield call(
      atendimentoController.validacaoContratacao,
      cpfFormated,
      [...new Set(matriculasContratacao)]
    )
    yield put(
      atendimentoActions.setInfosProduto(
        response?.data?.success,
        'modalResumoContratacao',
        index
      )
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message && !error.stack) {
      toast.warn(error.message)
      yield put(atendimentoActions.setTabAtendimentoActive(3, index))
    } else {
      handlerError(
        error,
        'Não foi possível realizar a validação para a contratação!'
      )
    }
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface InfosPortabilidade {
  quantidadeParcelas: number
  valorParcela: number
  codigoProduto: number // código do contrato
  bancoSimulacaoEnum: number
  numeroContaInterna: number // Não tem ainda
  valorTotalFinanciado: number // Produtos associados
  codigoTabelaFator: string // Produtos associados
  numeroContrato: string // numero proposta contrato
  saldoDevedor: number // valor saldo devedor contrato
  quantidadeParcelasAberto: number // quantidade total parcela
  quantidadeParcelasOriginal: number // quantidade parcela em aberto
  numeroProdutoRefin: number // código do produto associado
  codigoBanco: string
}

interface FinalizaContratacoesProps {
  produto: ProdutosSimulacao
  codigoTabelaSelecionado?: number
  parcelasSelecionado?: number
  codigoSimulacao?: number
  codigoPlano?: number | null
  descricaoSeguro?: string
  capitalSegurado?: number | null
  valorDoSeguro?: number
  infosPortabilidade?: InfosPortabilidade
}

function* finalizacaoContratacao({ cpf, index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  yield put(loadingActions.setLoading(true))
  const cpfFormated = removeMaskCPF(cpf)
  function* finalizaContratacoes({
    produto,
    codigoTabelaSelecionado,
    parcelasSelecionado,
    codigoSimulacao,
    codigoPlano,
    descricaoSeguro,
    capitalSegurado,
    valorDoSeguro,
    infosPortabilidade
  }: FinalizaContratacoesProps): Generator<
    CallEffect,
    void,
    AxiosResponse<ResponseWithMessages<ProdutosSimulacao[] | ReqFgts<null>>>
  > {
    try {
      switch (produto.servico) {
        case 2: {
          if (!codigoTabelaSelecionado || !parcelasSelecionado) return
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: false,
              flagSaqueParcelado: false,
              flagAberturaConta: false,
              quantidadeParcelas: parcelasSelecionado,
              valorParcela: produto.valorParcela,
              valorLiberado: produto.valorTotalFinanciado,
              finalidadeCredito: 1,
              servico: 1,
              numeroProduto: codigoTabelaSelecionado || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              flagRefin: true,
              tipoSaque: produto.tipoSaque
            }
          )

          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 3: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              numeroMatricula: produto.matricula,
              quantidadeParcelas: infosPortabilidade?.quantidadeParcelas || 0,
              valorParcela: infosPortabilidade?.valorParcela || 0,
              servico: produto.servico,
              numeroProduto: infosPortabilidade?.codigoProduto || 0,
              flagAberturaConta: true,
              bancoSimulacaoEnum: infosPortabilidade?.bancoSimulacaoEnum || 0,
              numeroContaInterna: produto.numeroContaInterna,
              tipoSaque: produto.tipoSaque,
              valorLiberado: infosPortabilidade?.valorTotalFinanciado || 0,
              codigoTabelaFator: infosPortabilidade?.codigoTabelaFator,
              numeroContrato: infosPortabilidade?.numeroContrato,
              saldoDevedor: infosPortabilidade?.saldoDevedor,
              quantidadeParcelasAberto:
                infosPortabilidade?.quantidadeParcelasAberto,
              quantidadeParcelasOriginal:
                infosPortabilidade?.quantidadeParcelasOriginal,
              numeroProdutoRefin: infosPortabilidade?.numeroProdutoRefin,
              codigoSimulacao: 0,
              codigoBanco: infosPortabilidade?.codigoBanco
            }
          )

          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 5: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagSaqueParcelado:
                produto.quantidadeParcelas != null ? true : false,
              flagAberturaConta: produto.flagAberturaConta,
              finalidadeCredito: 1,
              valorSaque: produto.flagSaqueAutorizado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              servico: produto.servico,
              valorParcela: produto.valorParcela,
              quantidadeParcelas: produto.quantidadeParcelas,
              valorLiberado: produto.valorTotalFinanciado,
              numeroProduto: produto.codigoProduto || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: produto.codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              numeroContaInterna: produto.numeroContaInterna,
              codigoPlano,
              descricaoSeguro,
              capitalSegurado,
              valorDoSeguro,
              tipoSaque: produto.tipoSaque
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 6: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagSaqueParcelado: produto.flagSaqueParcelado,
              flagAberturaConta: produto.flagAberturaConta,
              quantidadeParcelas: produto.quantidadeParcelas,
              valorParcela: produto.valorParcela,
              valorLiberado: produto.valorTotalFinanciado,
              finalidadeCredito: 1,
              valorSaque: produto.flagSaqueParcelado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              servico: produto.servico,
              numeroProduto: produto.codigoProduto || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: produto.codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              numeroContaInterna: produto.numeroContaInterna,
              codigoPlano,
              descricaoSeguro,
              capitalSegurado,
              valorDoSeguro,
              tipoSaque: produto.tipoSaque
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 7: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagSaqueParcelado: produto.flagSaqueParcelado,
              flagAberturaConta: produto.flagAberturaConta,
              quantidadeParcelas: produto.quantidadeParcelas,
              valorParcela: produto.valorParcela,
              valorLiberado: produto.valorTotalFinanciado,
              finalidadeCredito: 1,
              valorSaque:
                produto.flagSaqueAutorizado || produto.flagSaqueParcelado
                  ? Number(removeMaskMoney(produto.valorSaque))
                  : produto.valorLimite,
              servico: produto.servico,
              numeroProduto: produto.codigoProduto || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: produto.codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              numeroContaInterna: produto.numeroContaInterna,
              codigoPlano,
              descricaoSeguro,
              capitalSegurado,
              valorDoSeguro,
              tipoSaque: produto.tipoSaque
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 8: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: produto.flagSaqueAutorizado,
              flagSaqueParcelado: produto.flagSaqueParcelado,
              flagAberturaConta: produto.flagAberturaConta,
              quantidadeParcelas: produto.quantidadeParcelas,
              valorParcela: produto.valorParcela,
              valorLiberado: produto.valorTotalFinanciado,
              finalidadeCredito: 1,
              valorSaque: produto.flagSaqueAutorizado
                ? Number(removeMaskMoney(produto.valorSaque))
                : produto.valorLimite,
              servico: produto.servico,
              numeroProduto: produto.codigoProduto || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: produto.codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              numeroContaInterna: produto.numeroContaInterna,
              codigoPlano,
              descricaoSeguro,
              capitalSegurado,
              valorDoSeguro,
              tipoSaque: produto.tipoSaque
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
          break
        }
        case 9: {
          yield call(
            atendimentoController.finalizacaoContratacaoFgts,
            cpfFormated
          )
          toast.success('Contratação FGTS finalizada com sucesso!')
          break
        }
        default: {
          const response = yield call(
            atendimentoController.finalizacaoContratacaoCrm,
            {
              cpf: cpfFormated,
              flagSaqueAutorizado: false,
              flagSaqueParcelado: false,
              flagAberturaConta: false,
              quantidadeParcelas: produto.quantidadeParcelas,
              valorParcela: produto.valorParcela,
              finalidadeCredito: 1,
              servico: produto.servico,
              numeroProduto: produto.codigoProduto || 0,
              numeroMatricula: produto.matricula,
              codigoSimulacao: produto.codigoSimulacao || 0,
              bancoSimulacaoEnum: produto.bancoSimulacaoEnum,
              tipoSaque: produto.tipoSaque,
              valorLiberado: produto.valorTotalFinanciado
            }
          )
          if (
            response?.data?.content?.message ||
            response?.data?.content?.messages?.length > 0
          ) {
            if (response?.data?.content?.messages?.length > 0) {
              response?.data?.content?.messages?.map((message: string) => {
                toast.success(message)
              })
            } else {
              toast.success(response?.data?.content?.message)
            }
          } else {
            toast.success('Contratação finalizada com sucesso!')
          }
        }
      }
    } catch (error) {
      if (produto.servico !== 9) {
        handlerError(error, 'Não foi possível realizar a contratação!')
      } else {
        handlerErrorFgts(
          error,
          'Não foi possível realizar a contratação do FGTS!'
        )
      }
    }
  }

  yield all(
    atendimentosAbertos[index].tabProduto.produtosSelecionados?.flatMap(
      (produto) => {
        if (produto.servico === 2) {
          const codigoTabelaSelecionado = atendimentosAbertos[
            index
          ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
            (item) => item.id === produto.codigoSimulacao
          )?.value
          const parcelasSelecionado = atendimentosAbertos[
            index
          ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
            (item) => item.id === produto.codigoSimulacao
          )?.value

          const indexCodigoTabelaSelected = produto.listProdutos.findIndex(
            (p) => p.codigo === codigoTabelaSelecionado
          )
          const indexParcelasSelected = produto.listProdutos[
            indexCodigoTabelaSelected
          ].listParcelas.findIndex((i) => i.parcelas === parcelasSelecionado)

          const codigoSimulacao =
            produto.listProdutos[indexCodigoTabelaSelected].listParcelas[
              indexParcelasSelected
            ].codigoSimulacao

          return [
            call(finalizaContratacoes, {
              produto,
              codigoTabelaSelecionado,
              parcelasSelecionado,
              codigoSimulacao
            })
          ]
        }
        if (produto.servico === 3) {
          const contratosElegiveis =
            produto.contratosPortabilidade?.filter(
              (contratoPortabilidade) => contratoPortabilidade.clienteElegivel
            ) || []
          return contratosElegiveis.map((contratoPortabilidade) => {
            const portabilidade =
              contratoPortabilidade.listProdutosPortabilidade.find(
                (produtoPortabilidade) => produtoPortabilidade.selecionado
              )
            return call(finalizaContratacoes, {
              produto,
              infosPortabilidade: {
                quantidadeParcelas:
                  portabilidade?.produtosAssociados?.find(
                    (produtoAssociado) => produtoAssociado.selecionado
                  )?.listParcelas[0] || 0,
                valorParcela:
                  portabilidade?.produtosAssociados?.find(
                    (produtoAssociado) => produtoAssociado.selecionado
                  )?.valorParcela || 0,
                codigoProduto: portabilidade?.codigo || 0,
                bancoSimulacaoEnum: portabilidade?.banco || 0,
                numeroContaInterna: produto.numeroContaInterna || 0,
                valorTotalFinanciado:
                  portabilidade?.produtosAssociados?.find(
                    (produtoAssociado) => produtoAssociado.selecionado
                  )?.valorTotalFinanciado || 0,
                codigoTabelaFator:
                  portabilidade?.produtosAssociados?.find(
                    (produtoAssociado) => produtoAssociado.selecionado
                  )?.codigoTabelaFator ?? '',
                numeroContrato: contratoPortabilidade.numeroProposta || '',
                saldoDevedor: contratoPortabilidade.valorSaldoDevedor || 0,
                quantidadeParcelasAberto:
                  contratoPortabilidade.quantidadeParcelaEmAberto || 0,
                quantidadeParcelasOriginal:
                  contratoPortabilidade.quantidadeTotalParcela || 0,
                numeroProdutoRefin:
                  portabilidade?.produtosAssociados?.find(
                    (produtoAssociado) => produtoAssociado.selecionado
                  )?.codigo || 0,
                codigoBanco: contratoPortabilidade.codigoBanco
              }
            })
          })
        }
        if (produto.servico === 5) {
          const codigoPlano = atendimentosAbertos[
            index
          ].tabProduto.listPlanoSeguroPrestamistaConsignadoSelecionadoByMatricula?.find(
            (item) => item.matricula === produto.matricula
          )?.value

          const informacoes =
            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
              (plano) => plano.codigoPlano === codigoPlano
            )

          return [
            call(finalizaContratacoes, {
              produto,
              codigoTabelaSelecionado: 0,
              parcelasSelecionado: 0,
              codigoSimulacao: 0,
              codigoPlano: informacoes?.codigoPlano,
              descricaoSeguro: informacoes?.nomePlano,
              capitalSegurado: informacoes?.valorCapitalSegurado,
              valorDoSeguro: informacoes?.valorPremio
            })
          ]
        }
        if (produto.servico === 6) {
          const codigoPlano = atendimentosAbertos[
            index
          ].tabProduto.listPlanoSeguroPrestamistaCartaoConsignadoSelecionadoByMatricula?.find(
            (item) => item.matricula === produto.matricula
          )?.value

          const informacoes =
            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
              (plano) => plano.codigoPlano === codigoPlano
            )

          return [
            call(finalizaContratacoes, {
              produto,
              codigoTabelaSelecionado: 0,
              parcelasSelecionado: 0,
              codigoSimulacao: 0,
              codigoPlano: informacoes?.codigoPlano,
              descricaoSeguro: informacoes?.nomePlano,
              capitalSegurado: informacoes?.valorCapitalSegurado,
              valorDoSeguro: informacoes?.valorPremio
            })
          ]
        }
        if (produto.servico === 7) {
          const codigoPlano = atendimentosAbertos[
            index
          ].tabProduto.listPlanoSeguroPrestamistaCartaoBeneficioSelecionadoByMatricula?.find(
            (item) => item.matricula === produto.matricula
          )?.value

          const informacoes =
            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
              (plano) => plano.codigoPlano === codigoPlano
            )

          return [
            call(finalizaContratacoes, {
              produto,
              codigoTabelaSelecionado: 0,
              parcelasSelecionado: 0,
              codigoSimulacao: 0,
              codigoPlano: informacoes?.codigoPlano,
              descricaoSeguro: informacoes?.nomePlano,
              capitalSegurado: informacoes?.valorCapitalSegurado,
              valorDoSeguro: informacoes?.valorPremio
            })
          ]
        }
        if (produto.servico === 8) {
          const codigoPlano = atendimentosAbertos[
            index
          ].tabProduto.listPlanoSeguroPrestamistaBeneficioSelecionadoByMatricula?.find(
            (item) => item.matricula === produto.matricula
          )?.value

          const informacoes =
            produto.planosSeguroPrestamista?.planosComLimiteSaque?.find(
              (plano) => plano.codigoPlano === codigoPlano
            )

          return [
            call(finalizaContratacoes, {
              produto,
              codigoTabelaSelecionado: 0,
              parcelasSelecionado: 0,
              codigoSimulacao: 0,
              codigoPlano: informacoes?.codigoPlano,
              descricaoSeguro: informacoes?.nomePlano,
              capitalSegurado: informacoes?.valorCapitalSegurado,
              valorDoSeguro: informacoes?.valorPremio
            })
          ]
        }
        return [call(finalizaContratacoes, { produto })]
      }
    )
  )

  yield put(
    atendimentoActions.setInfosProduto(false, 'modalResumoContratacao', index)
  )
  yield put(atendimentoActions.getProdutosContratadosSaga(cpfFormated, index))
  yield put(
    atendimentoActions.getPropostasCrmSaga(
      atendimentosAbertos[index].cpf,
      index
    )
  )
  yield put(loadingActions.setLoading(false))
}

function* getDadosEndereco({ index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cepFormated = removeMaskCEP(
      atendimentosAbertos[index].tabInformacoes.infoCliente.cep
    )
    const response = yield call(
      atendimentoController.getDadosEndereco,
      cepFormated
    )
    yield put(
      atendimentoActions.setInformacoesField(
        response?.data?.content.localidade?.toUpperCase(),
        'cidade',
        index
      )
    )
    yield put(
      atendimentoActions.setInformacoesField(
        response?.data?.content.logradouro,
        'logradouro',
        index
      )
    )
    yield put(
      atendimentoActions.setInformacoesField(
        response?.data?.content.bairro,
        'bairro',
        index
      )
    )
    yield put(
      atendimentoActions.setInformacoesField(
        response?.data?.content.uf,
        'siglaEstado',
        index
      )
    )
  } catch (error) {
    yield put(atendimentoActions.cleanInformacoesEndereco(index))
    handlerError(error, 'Não foi possível buscar os dados de endereço!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetInformacoesClienteProps extends GenericProps {
  onUpdate?: boolean
}

function* getInformacoesCliente({
  cpf,
  index,
  onUpdate
}: GetInformacoesClienteProps): SagaIterator {
  try {
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.getInformacoesCliente,
      cpfFormated
    )

    const informacoes: InfoCliente = {
      bairro: response?.data?.content?.bairro,
      cep: maskCEP(response?.data?.content?.cep),
      cidade: response?.data?.content?.cidade,
      cidadeNascimento: response?.data?.content?.cidadeNascimento,
      complemento: response?.data?.content?.complemento,
      cpf: maskCPF(response?.data?.content?.cpf),
      dataEmissao: response?.data?.content?.dataEmissao,
      dataNascimento: response?.data?.content?.dataNascimento,
      ddd: response?.data?.content?.ddd,
      email: response?.data?.content?.email,
      escolaridadeId: response?.data?.content?.escolaridadeId,
      estadoCivil: response?.data?.content?.estadoCivil,
      logradouro: response?.data?.content?.logradouro,
      matriculas:
        response?.data?.content?.matriculas?.map((matricula: Matricula) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemCartaoDisponivel: maskMoney(
              Number(matricula.margemCartaoDisponivel)?.toFixed(2)
            ),
            margemDisponivel: maskMoney(
              Number(matricula.margemDisponivel)?.toFixed(2)
            ),
            margemCartaoBeneficio: maskMoney(
              Number(matricula.margemCartaoBeneficio)?.toFixed(2)
            ),
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia,
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta,
            digitoVerificadorConta: matricula.digitoVerificadorConta
          }
        }) || [],
      nome: response?.data?.content?.nome,
      nomeConjuge: response?.data?.content?.nomeConjuge,
      nomeMae: response?.data?.content?.nomeMae,
      nomePai: response?.data?.content?.nomePai,
      numero: response?.data?.content?.numero,
      numeroEndereco: response?.data?.content?.numeroEndereco,
      numeroIdentidade: response?.data?.content?.numeroIdentidade,
      orgaoEmissorId: response?.data?.content?.orgaoEmissorId,
      profissaoId: response?.data?.content?.profissaoId,
      sexo: response?.data?.content?.sexo,
      siglaEstado: response?.data?.content?.siglaEstado,
      ufNascimento: response?.data?.content?.ufNascimento,
      ufOrgaoEmissor: response?.data?.content?.ufOrgaoEmissor
    }
    yield put(atendimentoActions.getGenerosSaga(index))
    yield put(atendimentoActions.getOrgaosEmissoresSaga(index))
    yield put(atendimentoActions.getEstadosSaga(index))
    yield put(atendimentoActions.getEscolaridadesSaga(index))
    yield put(atendimentoActions.getEstadosCivisSaga(index))
    yield put(atendimentoActions.getTiposContaSaga(index))
    yield put(atendimentoActions.getBancosSaga(index))
    yield put(atendimentoActions.getProfissoesSaga(index))
    yield put(atendimentoActions.getCidadesSaga(index, informacoes.siglaEstado))
    yield put(
      atendimentoActions.getCidadesNascimentoSaga(
        index,
        informacoes.ufNascimento
      )
    )
    const matriculasInvalids: MatriculaInvalid[] =
      informacoes.matriculas?.map(() => {
        return {
          id: false,
          numeroMatricula: false,
          tipoPerfil: false,
          descricaoPerfil: false,
          tipoPerfilId: false,
          convenioId: false,
          margemDisponivel: false,
          margemCartaoDisponivel: false,
          margemCartaoBeneficio: false,
          contaCorrenteId: false,
          tipoContaId: false,
          bancoDadosBancariosId: false,
          numeroAgencia: false,
          digitoVerificadorAgencia: false,
          numeroConta: false,
          digitoVerificadorConta: false
        }
      }) || []
    yield put(
      atendimentoActions.setInvalidsInformacoesField(
        matriculasInvalids,
        'matriculas',
        index
      )
    )
    yield put(atendimentoActions.setInformacoes(informacoes, index))
    if (onUpdate) {
      yield put(atendimentoActions.getPropostasCrmSaga(cpf, index))
    }
  } catch (error) {
    //
  }
}

interface GetCidadesProps {
  type: string
  index: number
  siglaEstado?: string
}

function* getCidades({ index, siglaEstado }: GetCidadesProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const estado =
      siglaEstado ||
      atendimentosAbertos[index].tabInformacoes.infoCliente.siglaEstado
    let cidades = []
    if (estado) {
      const response = yield call(atendimentoController.getCidades, estado)
      cidades = response?.data?.content
    }

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(cidades, 'cidade', index)
    )
  } catch (error) {
    //
  }
}

function* getCidadesNascimento({
  index,
  siglaEstado
}: GetCidadesProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const estado =
      siglaEstado ||
      atendimentosAbertos[index].tabInformacoes.infoCliente.ufNascimento
    let cidades = []
    if (estado) {
      const response = yield call(atendimentoController.getCidades, estado)
      cidades = response?.data?.content
    }

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        cidades,
        'cidadeNascimento',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getGeneros({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getGeneros)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'genero',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getOrgaosEmissores({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getOrgaosEmissores)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'orgaoEmissor',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getEstados({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getEstados)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'ufOrgaoEmissor',
        index
      )
    )
    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'ufNascimento',
        index
      )
    )
    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'uf',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getEscolaridades({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getEscolaridades)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'escolaridade',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getEstadosCivis({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getEstadosCivis)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'estadoCivil',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* getTiposConta({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getTiposConta)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'tipoConta',
        index
      )
    )
  } catch (error) {
    //
  }
}

function* salvarInformacoesCliente({ index }: GenericProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const infosCliente = {
      ...atendimentosAbertos[index].tabInformacoes.infoCliente
    }
    const infosClienteFormated: InfoCliente = {
      nome: infosCliente.nome,
      cpf: removeMaskCPF(infosCliente.cpf),
      dataNascimento: infosCliente.dataNascimento,
      nomePai: infosCliente.nomePai,
      nomeMae: infosCliente.nomeMae,
      numeroIdentidade: infosCliente.numeroIdentidade,
      orgaoEmissorId: infosCliente.orgaoEmissorId,
      ufOrgaoEmissor: infosCliente.ufOrgaoEmissor,
      dataEmissao: infosCliente.dataEmissao,
      ddd: infosCliente.ddd,
      numero: infosCliente.numero,
      email: infosCliente.email,
      sexo: infosCliente.sexo,
      estadoCivil: infosCliente.estadoCivil,
      cidadeNascimento: infosCliente.cidadeNascimento,
      ufNascimento: infosCliente.ufNascimento,
      matriculas:
        infosCliente.matriculas?.map((matricula) => {
          return {
            id: matricula.id,
            numeroMatricula: matricula.numeroMatricula,
            tipoPerfil: matricula.tipoPerfil,
            descricaoPerfil: matricula.descricaoPerfil,
            tipoPerfilId: matricula.tipoPerfilId,
            convenioId: matricula.convenioId,
            margemDisponivel: removeMaskMoney(matricula.margemDisponivel),
            margemCartaoDisponivel: removeMaskMoney(
              matricula.margemCartaoDisponivel
            ),
            margemCartaoBeneficio: removeMaskMoney(
              matricula.margemCartaoBeneficio
            ),
            contaCorrenteId: matricula.contaCorrenteId,
            tipoContaId: matricula.tipoContaId,
            bancoDadosBancariosId: matricula.bancoDadosBancariosId,
            numeroAgencia: matricula.numeroAgencia,
            digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
            numeroConta: matricula.numeroConta,
            digitoVerificadorConta: matricula.digitoVerificadorConta
          }
        }) || null,
      escolaridadeId: infosCliente.escolaridadeId,
      cep: infosCliente.cep,
      cidade: infosCliente.cidade,
      siglaEstado: infosCliente.siglaEstado,
      logradouro: infosCliente.logradouro,
      numeroEndereco: infosCliente.numeroEndereco,
      bairro: infosCliente.bairro,
      complemento: infosCliente.complemento,
      nomeConjuge: infosCliente.nomeConjuge,
      profissaoId: infosCliente.profissaoId
    }
    const response = yield call(
      atendimentoController.salvarInformacoesCliente,
      infosClienteFormated
    )
    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content?.nome,
        'nome',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosContatoField(
        maskDate(response?.data?.content?.dataNascimento, 'DD/MM/YYYY'),
        'dataNascimento',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content?.cidade,
        'cidade',
        index
      )
    )
    yield put(
      atendimentoActions.setInfosContatoField(
        response?.data?.content?.sexo === 'M' ? 'Masculino' : 'Feminino',
        'sexo',
        index
      )
    )
    toast.success('Informações atualizadas com sucesso!')
    yield put(atendimentoActions.cleanInformacoes(index))
    yield put(
      atendimentoActions.getInformacoesClienteSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
    const responseTelefones = yield call(
      atendimentoController.getTelefonesValidos,
      atendimentosAbertos[index].cpf
    )
    const telefones: InfosChamada['telefones'] = []
    responseTelefones?.data?.content?.forEach((telefone: Telefone) => {
      telefones.push({
        numero: `(${telefone.ddd}) ${telefone.numero}`,
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContatoField(
        {
          ...atendimentosAbertos[index].tabContato.infosChamada,
          telefones: telefones
        },
        'infosChamada',
        index
      )
    )
    yield put(
      atendimentoActions.getTelefonesSaga(atendimentosAbertos[index].cpf, index)
    )
    yield put(
      atendimentoActions.setInfosProduto([], 'produtosDisponiveis', index)
    )
    yield put(
      atendimentoActions.setInfosProduto([], 'produtosSelecionados', index)
    )
    yield put(atendimentoActions.setInfosProduto([], 'beneficios', index))
    const responsePerfilCliente = yield call(
      atendimentoController.getPerfilCliente,
      atendimentosAbertos[index].cpf
    )
    const perfis: Beneficio[] = []
    responsePerfilCliente?.data?.content?.map((perfil: Beneficio) => {
      perfis.push({
        clientePerfil: {
          id: perfil.clientePerfil.id,
          descricao: perfil.clientePerfil.descricao,
          tipoPerfil: perfil.clientePerfil.tipoPerfil
        },
        dataAtualizacaoToken: perfil.dataAtualizacaoToken,
        dataRespostaIn100: perfil.dataRespostaIn100,
        dataConsentimento: perfil.dataConsentimento,
        numero: perfil.numero,
        loading: false
      })
    })
    yield put(atendimentoActions.setInfosProduto(perfis, 'beneficios', index))
    yield put(
      atendimentoActions.getPropostasCrmSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível atualizar as informações do cliente!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getBancos({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getBancos)

    yield put(
      atendimentoActions.setOptionsSelectsInformacoes(
        response?.data?.content,
        'banco',
        index
      )
    )
  } catch (error) {
    //
  }
}

interface GetContratosProps extends GenericProps {
  indexBeneficio: number
}

function* getContratos({
  cpf,
  index,
  indexBeneficio
}: GetContratosProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const response = yield call(
      atendimentoController.getContratos,
      cpf,
      atendimentosAbertos[index].tabHistorico?.[indexBeneficio].tabelaContratos
        .page,
      atendimentosAbertos[index].tabHistorico?.[indexBeneficio].tabelaContratos
        .pageSize,
      atendimentosAbertos[index].tabProduto.beneficios[indexBeneficio].numero
    )
    const contratos: Contratos[] = []
    response?.data?.content?.registros.forEach((contrato: Contratos) =>
      contratos.push({
        codigoBanco: contrato.codigoBanco,
        banco: contrato.banco,
        id: contrato.id,
        matricula: contrato.matricula,
        numeroContrato: contrato.numeroContrato,
        valorContrato: contrato.valorContrato,
        tipoEmprestimo: contrato.tipoEmprestimo,
        inicioContrato: contrato.inicioContrato,
        fimContrato: contrato.fimContrato,
        parcelasEmAberto: contrato.parcelasEmAberto,
        quantidadeParcelas: contrato.quantidadeParcelas,
        valorParcela: contrato.valorParcela,
        dataAverbacao: contrato.dataAverbacao,
        situacao: contrato.situacao,
        taxa: contrato.taxa,
        saldoDevedorAproximado: contrato.saldoDevedorAproximado,
        valorReservado: contrato.valorReservado
      })
    )
    yield put(
      atendimentoActions.setInfosHistorico(
        contratos,
        'contratos',
        index,
        indexBeneficio
      )
    )
    yield put(
      atendimentoActions.setPaginationContratos(
        response?.data?.content?.quantidadeTotal,
        'totalRegistros',
        index,
        indexBeneficio
      )
    )
  } catch (error) {
    //
  }
}

function* getInfosClienteConsig({
  index,
  indexBeneficio
}: GetContratosProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const response = yield call(
      atendimentoController.getInfosClienteConsig,
      atendimentosAbertos[index].cpf,
      atendimentosAbertos[index].tabProduto.beneficios[indexBeneficio].numero
    )
    const infosConsig: InfosConsig = {
      nome: response?.data?.content?.nome,
      idade: response?.data?.content?.idade,
      valorRenda: response?.data?.content?.valorRenda,
      margemDisponivel: response?.data?.content?.margemDisponivel,
      margemCartaoConsignado: response?.data?.content?.margemCartaoConsignado,
      margemCartaoBeneficio: response?.data?.content?.margemCartaoBeneficio,
      situacao: response?.data?.content?.situacao,
      especie: response?.data?.content?.especie,
      possuiRepresentanteLegalProcurador:
        response?.data?.content?.possuiRepresentanteLegalProcurador,
      bloqueioEmprestismo: response?.data?.content?.bloqueioEmprestismo,
      beneficioPermiteEmprestimo:
        response?.data?.content?.beneficioPermiteEmprestimo,
      pensaoAlimenticia: response?.data?.content?.pensaoAlimenticia,
      dataDespachoBeneficio: response?.data?.content?.dataDespachoBeneficio
    }
    yield put(
      atendimentoActions.setInfosHistorico(
        infosConsig,
        'infosConsig',
        index,
        indexBeneficio
      )
    )
  } catch (error) {
    //
  }
}

function* getInformacoesSimplificadas({ cpf }: GenericProps): SagaIterator {
  const { modalCadastroCliente }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    const response = yield call(
      atendimentoController.getInformacoesSimplificadas,
      cpf
    )
    yield put(
      atendimentoActions.setInfosCadastroClienteModal({
        ...modalCadastroCliente,
        dataNascimento: maskDate(
          response?.data?.content?.dataNascimento,
          'YYYY-MM-DD'
        ),
        nome: response?.data?.content?.nome,
        ddd: response?.data?.content?.ddd,
        telefone: response?.data?.content?.telefone
      })
    )
  } catch (error) {
    handlerError(
      error,
      'Não foi possível obter as informações deste cliente pela Consignado Rápido!'
    )
  }
}

interface AdicionarNovoTelefoneProps extends GenericProps {
  telefone: string
  isWhatsApp: boolean | null
}

function* adicionarNovoTelefone({
  index,
  telefone,
  isWhatsApp
}: AdicionarNovoTelefoneProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield call(
      atendimentoController.adicionarNovoTelefone,
      atendimentosAbertos[index].cpf,
      telefone,
      isWhatsApp
    )
    const responseTelefones = yield call(
      atendimentoController.getTelefonesValidos,
      atendimentosAbertos[index].cpf
    )
    const telefones: InfosChamada['telefones'] = []
    responseTelefones?.data?.content?.forEach((telefone: Telefone) => {
      telefones.push({
        numero: maskPhoneWithDDD(telefone.ddd + telefone.numero),
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContatoField(
        {
          ...atendimentosAbertos[index].tabContato.infosChamada,
          telefones: telefones
        },
        'infosChamada',
        index
      )
    )
    yield put(
      atendimentoActions.getInformacoesClienteSaga(
        atendimentosAbertos[index].cpf,
        index,
        true
      )
    )
    toast.success('Novo telefone adicionado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível adicionar um novo telefone')
  }
}

function* getMatriculasModalIndicacao({
  cpf,
  index
}: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getPerfilCliente, cpf)
    const matriculas: string[] = []
    response.data?.content?.map((content: Beneficio) => {
      matriculas.push(content.numero)
    })

    yield put(
      atendimentoActions.setInfosProduto(
        matriculas,
        'matriculasModalIndicacao',
        index
      )
    )
  } catch (error) {
    handlerError(error)
    yield put(
      atendimentoActions.setInfosProduto([], 'matriculasModalIndicacao', index)
    )
  }
}

interface RecalculoSimulacaoRefinItauProps extends GenericProps {
  cpf: string
  numeroMatricula: string
  quantidadeParcelas: number
  valorParcela: number
  servico: number
  codigoProduto: number
  banco: number
  contrato: string
  valorAgregacao: number
  index: number
  id: string
  codigoSimulacao: number
  novaMargem: number
}
function* recalculoSimulacaoRefinItau({
  cpf,
  numeroMatricula,
  quantidadeParcelas,
  valorParcela,
  servico,
  codigoProduto,
  banco,
  contrato,
  valorAgregacao,
  index,
  id,
  codigoSimulacao,
  novaMargem
}: RecalculoSimulacaoRefinItauProps): SagaIterator {
  const { atendimentosAbertos }: ATENDIMENTO = yield select(
    (state: ApplicationState) => state.atendimento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormated = removeMaskCPF(cpf)
    const response = yield call(
      atendimentoController.recalculoSimulacaoRefinItau,
      cpfFormated,
      numeroMatricula,
      quantidadeParcelas,
      valorParcela,
      servico,
      codigoProduto,
      banco,
      contrato,
      valorAgregacao
    )

    const produtosDisponiveis = [
      ...atendimentosAbertos[index].tabProduto.produtosDisponiveis
    ]

    const produtosSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]

    const indexProdutoDisponivel = produtosDisponiveis.findIndex(
      (produto) => produto.id === id
    )

    const indexProdutoSelecionado = produtosSelecionados.findIndex(
      (produto) => produto.id === id
    )

    const newCodigoTabelaSelected = response.data.content.listProdutos[0].codigo

    const listParcelas = response.data.content.listProdutos[0].listParcelas

    const newParcelasSelected = listParcelas?.reduce(
      (max: number, parcela: ListaParcelasEValorTotalSimulacao) => {
        return parcela.parcelas > max ? parcela.parcelas : max
      },
      listParcelas[0].parcelas
    )

    const indexCodigoTabelaSelected = atendimentosAbertos[
      index
    ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.findIndex(
      (item) => item.id === codigoSimulacao
    )

    const indexParcelasSelected = atendimentosAbertos[
      index
    ].tabProduto.listParcelasSelectedByCodigoSimulacao?.findIndex(
      (item) => item.id === codigoSimulacao
    )

    const listCodigoTabelaSelectedByCodigoSimulacao = [
      ...atendimentosAbertos[index].tabProduto
        .listCodigoTabelaSelectedByCodigoSimulacao
    ]

    listCodigoTabelaSelectedByCodigoSimulacao[indexCodigoTabelaSelected] = {
      id: response.data.content.codigoSimulacao,
      value: newCodigoTabelaSelected
    }

    const listParcelasSelectedByCodigoSimulacao = [
      ...atendimentosAbertos[index].tabProduto
        .listParcelasSelectedByCodigoSimulacao
    ]

    listParcelasSelectedByCodigoSimulacao[indexParcelasSelected] = {
      id: response.data.content.codigoSimulacao,
      value: newParcelasSelected
    }

    yield put(
      atendimentoActions.setInfosProduto(
        listCodigoTabelaSelectedByCodigoSimulacao,
        'listCodigoTabelaSelectedByCodigoSimulacao',
        index
      )
    )

    yield put(
      atendimentoActions.setInfosProduto(
        listParcelasSelectedByCodigoSimulacao,
        'listParcelasSelectedByCodigoSimulacao',
        index
      )
    )

    if (indexProdutoDisponivel !== -1) {
      produtosDisponiveis[indexProdutoDisponivel] = response.data.content

      yield put(
        atendimentoActions.setInfosProduto(
          produtosDisponiveis,
          'produtosDisponiveis',
          index
        )
      )
    }

    if (indexProdutoSelecionado !== -1) {
      produtosSelecionados[indexProdutoSelecionado] = response.data.content

      yield put(
        atendimentoActions.setInfosProduto(
          produtosSelecionados,
          'produtosSelecionados',
          index
        )
      )
    }

    const margemDisponivelByMatricula =
      atendimentosAbertos[index].tabProduto.margemDisponivelByMatricula

    const indexMargemDisponivelByMatricula =
      margemDisponivelByMatricula.findIndex(
        (item) => item.matricula === numeroMatricula
      )

    margemDisponivelByMatricula[indexMargemDisponivelByMatricula] = {
      matricula: numeroMatricula,
      value: novaMargem
    }

    yield put(
      atendimentoActions.setInfosProduto(
        margemDisponivelByMatricula,
        'margemDisponivelByMatricula',
        index
      )
    )

    if (response?.data?.messages?.length > 0) {
      handlerError(response?.data)
    }
    toast.success('Recálculo realizado com sucesso!')
  } catch (error) {
    handlerError(error, 'Não foi possível recalcular!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* verificaNaoPertube({ cpf }: GenericProps): SagaIterator {
  try {
    const cpfFormatted = removeMaskCPF(cpf)
    yield call(atendimentoController.verificaNaopertube, cpfFormatted)
  } catch (error) {
    handlerError(error)
  }
}

interface GetInformacoesLeadProps extends GenericProps {
  matriculas: string[]
}

function* getInformacoesLead({
  cpf,
  matriculas,
  index
}: GetInformacoesLeadProps): SagaIterator {
  try {
    const cpfFormatted = removeMaskCPF(cpf)

    const responses: AxiosResponse<ReqCrm<InfosMatriculaLead>>[] = yield all(
      matriculas.map((matricula) =>
        call(atendimentoController.getInformacoesLead, cpfFormatted, matricula)
      )
    )

    const infosLead: InfosMatriculaLead[] = responses
      .filter(
        (response) =>
          !!response?.data?.content && !!response?.data?.content?.matricula
      )
      .map((response) => response.data.content)
    yield put(
      atendimentoActions.setInfosChamadaContato(
        infosLead,
        'infosMatriculasLead',
        index
      )
    )
  } catch (error) {
    handlerError(error)
  }
}

function* getIntegracao({ index }: GenericProps): SagaIterator {
  try {
    const response = yield call(atendimentoController.getIntegracao)
    yield put(
      atendimentoActions.setIntegracao(response?.data?.content.url, index)
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    yield put(
      atendimentoActions.setMensagemErroIntegracao(error?.message ?? '', index)
    )
  }
}

export function* watchGetFormularioAtendimento30() {
  yield takeEvery(
    TYPES.GET_FORMULARIO_ATENDIMENTO_30_SAGA,
    getFormularioAtendimento30
  )
}

export function* watchSalvarFormularioAtendimento30() {
  yield takeEvery(
    TYPES.SALVAR_FORMULARIO_ATENDIMENTO_30_SAGA,
    salvarFormularioAtendimento30
  )
}

export function* watchGetAtendimentos() {
  yield takeEvery(TYPES.START_ATENDIMENTO_SAGA, getAtendimentos)
}

export function* watchFinalizaAgendamento() {
  yield takeEvery(TYPES.FINALIZA_AGENDAMENTO_SAGA, finalizaAgendamento)
}

export function* watchEnriquecerDados() {
  yield takeEvery(TYPES.ENRIQUECER_DADOS_SAGA, enriquecerDados)
}

export function* watchValidarNovoAtendimento() {
  yield takeEvery(TYPES.VALIDAR_NOVO_ATENDIMENTO_SAGA, validarNovoAtendimento)
}

export function* watchGetConvenios() {
  yield takeEvery(TYPES.GET_CONVENIOS_SAGA, getConvenios)
}

export function* watchGetConveniosInfo() {
  yield takeEvery(TYPES.GET_CONVENIOS_INFO_SAGA, getConveniosInfo)
}

export function* watchGetPerfis() {
  yield takeEvery(TYPES.GET_PERFIS_SAGA, getPerfis)
}

export function* watchGetProfissoes() {
  yield takeEvery(TYPES.GET_PROFISSOES_SAGA, getProfissoes)
}

export function* watchSalvarCadastroSimplificado() {
  yield takeEvery(
    TYPES.SALVAR_CADASTRO_SIMPLIFICADO_SAGA,
    salvarCadastroSimplificado
  )
}

export function* watchGetPerfilCliente() {
  yield takeEvery(TYPES.GET_PERFIL_CLIENTE_SAGA, getPerfilCliente)
}

export function* watchDiscarCliente() {
  yield takeEvery(TYPES.DISCAR_CLIENTE_SAGA, discarCliente)
}

export function* watchGetListaAtendimentos() {
  yield takeEvery(TYPES.GET_LISTA_ATENDIMENTOS_SAGA, getListaAtendimentos)
}

export function* watchGetTabulacoes() {
  yield takeEvery(TYPES.GET_TABULACOES_SAGA, getTabulacoes)
}

export function* watchGetTiposAgendamento() {
  yield takeEvery(TYPES.GET_TIPOS_ATENDIMENTO_SAGA, getTiposAgendamento)
}

export function* watchTabularAtendimento() {
  yield takeEvery(TYPES.TABULAR_ATENDIMENTO_SAGA, tabularAtendimento)
}

export function* watchTabularAtendimentobyCpf() {
  yield takeEvery(
    TYPES.TABULAR_ATENDIMENTO_DISPATCH_SAGA,
    tabularAtendimentobyCpf
  )
}

export function* watchGetPropostasCrm() {
  yield takeEvery(TYPES.GET_PROPOSTAS_CRM_SAGA, getPropostasCrm)
}

export function* watchSolicitarSimulacaoFgts() {
  yield takeEvery(TYPES.SOLICITAR_SIMULACAO_FGTS_SAGA, solicitarSimulacaoFgts)
}

export function* watchGetParcelasFgts() {
  yield takeEvery(TYPES.GET_PARCELAS_FGTS_SAGA, getParcelasFgts)
}

export function* watchRecalculoProposta() {
  yield takeEvery(TYPES.RECALCULO_PROPOSTA_SAGA, recalculoProposta)
}

export function* watchRecalculoPropostaFgts() {
  yield takeEvery(TYPES.RECALCULO_PROPOSTA_FGTS_SAGA, recalculoPropostaFgts)
}

export function* watchGetResumoContratacao() {
  yield takeEvery(TYPES.GET_RESUMO_CONTRATACAO_SAGA, getResumoContratacao)
}

export function* watchGetSolicitacoesIN100() {
  yield takeEvery(TYPES.GET_SOLICITACOES_IN100_SAGA, getSolicitacoesIN100)
}

export function* watchGetTelefones() {
  yield takeEvery(TYPES.GET_TELEFONES_SAGA, getTelefones)
}

export function* watchInserirSolicitacaoIN100() {
  yield takeEvery(
    TYPES.INSERIR_SOLICITACOES_IN100_SAGA,
    inserirSolicitacaoIN100
  )
}

export function* watchValidaStatusIN100() {
  yield takeEvery(TYPES.VALIDA_STATUS_IN100_SAGA, validaStatusIN100)
}

export function* watchValidacaoIN100() {
  yield takeEvery(TYPES.VALIDACAO_IN100_SAGA, validacaoIN100)
}

export function* watchConsentimentoItau() {
  yield takeEvery(TYPES.CONSENTIMENTO_ITAU_SAGA, consentimentoItau)
}
export function* watchConsultaConsentimentoItau() {
  yield takeEvery(
    TYPES.CONSULTA_CONSENTIMENTO_ITAU_SAGA,
    consultaConsentimentoItau
  )
}

export function* watchGetProdutosContratados() {
  yield takeEvery(TYPES.GET_PRODUTOS_CONTRATADOS_SAGA, getProdutosContratados)
}

export function* watchValidacaoContratacao() {
  yield takeEvery(TYPES.VALIDACAO_CONTRATACAO_SAGA, validacaoContratacao)
}

export function* watchFinalizacaoContratacao() {
  yield takeEvery(TYPES.FINALIZACAO_CONTRATACAO_SAGA, finalizacaoContratacao)
}

export function* watchGetDadosEndereco() {
  yield takeEvery(TYPES.GET_DADOS_ENDERECO_SAGA, getDadosEndereco)
}

export function* watchGetInformacoesCliente() {
  yield takeEvery(TYPES.GET_INFORMACOES_CLIENTE_SAGA, getInformacoesCliente)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetCidadesNascimento() {
  yield takeEvery(TYPES.GET_CIDADES_NASCIMENTO_SAGA, getCidadesNascimento)
}

export function* watchGetGeneros() {
  yield takeEvery(TYPES.GET_GENEROS_SAGA, getGeneros)
}

export function* watchGetOrgaosEmissores() {
  yield takeEvery(TYPES.GET_ORGAOS_EMISSORES_SAGA, getOrgaosEmissores)
}

export function* watchGetEstados() {
  yield takeEvery(TYPES.GET_ESTADOS_SAGA, getEstados)
}

export function* watchGetEscolaridades() {
  yield takeEvery(TYPES.GET_ESCOLARIDADES_SAGA, getEscolaridades)
}

export function* watchGetEstadosCivis() {
  yield takeEvery(TYPES.GET_ESTADOS_CIVIS_SAGA, getEstadosCivis)
}

export function* watchGetTiposConta() {
  yield takeEvery(TYPES.GET_TIPOS_CONTA_SAGA, getTiposConta)
}

export function* watchGetBancos() {
  yield takeEvery(TYPES.GET_BANCOS_SAGA, getBancos)
}

export function* watchSalvarInformacoesCliente() {
  yield takeEvery(
    TYPES.SALVAR_INFORMACOES_CLIENTE_SAGA,
    salvarInformacoesCliente
  )
}

export function* watchGetContratos() {
  yield takeEvery(TYPES.GET_CONTRATOS_SAGA, getContratos)
}

export function* watchGetInfosClienteConsig() {
  yield takeEvery(TYPES.GET_INFOS_CLIENTE_CONSIG_SAGA, getInfosClienteConsig)
}

export function* watchGetInformacoesSimplificadas() {
  yield takeEvery(
    TYPES.GET_INFORMACOES_SIMPLIFICADAS_SAGA,
    getInformacoesSimplificadas
  )
}

export function* watchAdicionarNovoTelefone() {
  yield takeEvery(TYPES.ADICIONAR_NOVO_TELEFONE_SAGA, adicionarNovoTelefone)
}

export function* watchGetMatriculasModalIndicacao() {
  yield takeEvery(
    TYPES.GET_MATRICULAS_MODAL_INDICACAO_SAGA,
    getMatriculasModalIndicacao
  )
}

export function* watchRecalculoSimulacaoRefinItau() {
  yield takeEvery(
    TYPES.RECALCULO_SIMULACAO_REFIN_ITAU_SAGA,
    recalculoSimulacaoRefinItau
  )
}

export function* watchVerificaNaoPertube() {
  yield takeEvery(TYPES.VERIFICA_NAO_PERTUBE_SAGA, verificaNaoPertube)
}

export function* watchGetInformacoesLead() {
  yield takeEvery(TYPES.GET_INFORMACOES_LEAD_SAGA, getInformacoesLead)
}

export function* watchGetIntegracao() {
  yield takeEvery(TYPES.GET_INTEGRACAO_SAGA, getIntegracao)
}

function* atendimentoSaga() {
  yield all([
    fork(watchGetFormularioAtendimento30),
    fork(watchSalvarFormularioAtendimento30),
    fork(watchGetAtendimentos),
    fork(watchFinalizaAgendamento),
    fork(watchEnriquecerDados),
    fork(watchValidarNovoAtendimento),
    fork(watchGetConvenios),
    fork(watchGetConveniosInfo),
    fork(watchGetPerfis),
    fork(watchGetProfissoes),
    fork(watchSalvarCadastroSimplificado),
    fork(watchGetPerfilCliente),
    fork(watchDiscarCliente),
    fork(watchGetListaAtendimentos),
    fork(watchGetTabulacoes),
    fork(watchGetTiposAgendamento),
    fork(watchTabularAtendimento),
    fork(watchTabularAtendimentobyCpf),
    fork(watchGetPropostasCrm),
    fork(watchSolicitarSimulacaoFgts),
    fork(watchGetParcelasFgts),
    fork(watchRecalculoProposta),
    fork(watchRecalculoPropostaFgts),
    fork(watchGetResumoContratacao),
    fork(watchGetSolicitacoesIN100),
    fork(watchGetTelefones),
    fork(watchInserirSolicitacaoIN100),
    fork(watchValidaStatusIN100),
    fork(watchValidacaoIN100),
    fork(watchConsentimentoItau),
    fork(watchGetProdutosContratados),
    fork(watchValidacaoContratacao),
    fork(watchFinalizacaoContratacao),
    fork(watchGetDadosEndereco),
    fork(watchGetInformacoesCliente),
    fork(watchGetCidades),
    fork(watchGetCidadesNascimento),
    fork(watchGetGeneros),
    fork(watchGetOrgaosEmissores),
    fork(watchGetEstados),
    fork(watchGetEscolaridades),
    fork(watchGetEstadosCivis),
    fork(watchGetTiposConta),
    fork(watchGetBancos),
    fork(watchSalvarInformacoesCliente),
    fork(watchGetContratos),
    fork(watchGetInfosClienteConsig),
    fork(watchGetInformacoesSimplificadas),
    fork(watchAdicionarNovoTelefone),
    fork(watchGetMatriculasModalIndicacao),
    fork(watchRecalculoSimulacaoRefinItau),
    fork(watchVerificaNaoPertube),
    fork(watchConsultaConsentimentoItau),
    fork(watchGetInformacoesLead),
    fork(watchGetIntegracao)
  ])
}

export default atendimentoSaga
